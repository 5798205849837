import React from "react";
import { useState } from "react";
import UserIcon from "../assets/images/user-icon.png";
import MedicalCross from "../assets/images/medical-cross.png";
import PatientView from "./Modals/PatientView";

const RoomBoxItem = ({
  bed,
  onDragStart,
  onDragEnd,
  id,
  RoomArray,
  roomName,
  showRoomName,
  isEditable
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div
      className="bed-box d-flex flex-column"
      id={id}
      draggable={isEditable}
      onDragStart={(e) => onDragStart(e)}
      onDragEnd={(e) => onDragEnd(e)}
    >
      {bed === "fill" ? (
        RoomArray.map((item) => {
          return id === parseInt(item.bedId) ? (
            <div className="floor-waiting-bed">
              <div className="bed-box ">
                <div className="bed position-relative align-items-center null">
                  <div className="lefticon ">
                    <img src={UserIcon} alt="" className="user-icon" />
                  </div>
                  <div className="bed-patient-details d-flex flex-column text-dark">
                    <img
                      src={MedicalCross}
                      alt=""
                      className="medical-cross-icon w-100"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null;
        })
      ) : (
        <>
            {
                showRoomName ? (<div className="roomname">{roomName}</div>) : (
                    <>
                        <div
                            className={`bed position-relative align-items-center ${
                            bed === "fill" ? "" : null
                            }`}
                        >
                            <div className="lefticon ">
                            <img src={UserIcon} alt="" className="user-icon" />
                            </div>
                            <div className="bed-patient-details d-flex flex-column text-dark">
                            <img src={MedicalCross} alt="" className="medical-cross-icon" />
                            </div>
                        </div>
                    </>
                )
            }         
          
        </>
      )}

      {/* 
    <PatientView show={show} handleClose={handleClose} handleShow={handleShow} /> */}
    </div>
  );
};

export default RoomBoxItem;