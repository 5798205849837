import React from 'react';
import StringInput from './StringInput';
import DefaultInput from './DefaultInput';
import DateInput from './DateInput';
import TimeInput from './TimeInput';
import DateTimeOffsetInput from './DateTimeOffsetInput';
import BooleanInput from './BooleanInput';
import DecimalInput from './DecimalInput';

const DynamicInput = ({ item }) => {
    const { Name, Type, MaxLength } = item?._attributes;
    // Determine the input component based on data type
    const getInputComponent = () => {
        switch (Type) {
            case 'Edm.String':
                return <StringInput name={Name} />;
            case 'Edm.Decimal':
                return <DecimalInput name={Name} />;
            case 'Edm.Boolean':
                return <BooleanInput name={Name} />;
            case 'Edm.Date':
                return <DateInput name={Name} />;
            case 'Edm.TimeOfDay':
                return <TimeInput name={Name} />;
            case 'Edm.DateTimeOffset':
                return <DateTimeOffsetInput name={Name} />;
            default:
                return <DefaultInput name={Name} />;
        }
    };
  
    return (
        <div className="row mb-2">
            <div className="col-md-3 d-flex align-items-center">
                <label htmlFor={Name} className="form-label">{Name}</label>
            </div>
            <div className="col-md-9">
                {getInputComponent()}
            </div>
        </div>
    );
};
 
export default DynamicInput;
