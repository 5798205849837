import React from 'react'
import FeatherIcon from 'feather-icons-react';
import { Col } from 'react-bootstrap'
import CalendarIcon from '../assets/images/calendar-icon.png'
import JaneImage from "../assets/images/jane-image.png"
import { Dropdown } from 'react-bootstrap';

const CardImageViewItem = (props) => {
  return (
    <Col md={4} className='mb-4'>
        <div className='card-view-item bg-light rounded position-relative p-0 overflow-hidden py-4'>                   
              {/* <Dropdown>
                <Dropdown.Toggle className='d-flex bg-transparent border-0 text-start text-dark py-0 fs-3 lh-1'> 
                  <FeatherIcon icon="more-horizontal" className='ms-auto me-2' /> 
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">View</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Edit</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>       */}
            <div className='vip-btn bg-blue-dark rounded d-flex align-items-center justify-content-center text-light position-absolute top-6 end-0 mt-4 me-4' style={{width:"38px", height:"19px"}}>VIP</div>
            <div className='card-details-block d-flex align-items-center'>
            <img src={JaneImage} alt='' className='ps-4' />
                <div className='card-details'>
                  <h5 className='text-dark px-4 m-0'>{props?.details?.FirstName + " " + props?.details?.LastName}</h5>
                    <div className='card-date px-4'>
                      <span>{props?.details?.BirthDate}, {props?.details?.IsSexUnknown ? "Others" : props?.details?.IsMale ? "Male" : "Female"}</span>                        
                  </div>
                    <div className='card-days px-4 d-flex align-items-center'>
                        <img src={CalendarIcon} alt='' className='me-2' />703 days
                    </div>
                    <span className='px-4'>Internal Med / Inn Station2 / 2.01</span>
                </div>
            </div>        
            <div className='card-grey-box px-4 py-2 mt-2 mb-3 text-dark' style={{background:"#D4DBEC"}}>
              <strong className='text-blue-dark'>Attending Phy.</strong> Prof. Dr. Grun Gerda
              <div>Diagnoses Exist</div>
            </div>
            <div className='patient-count px-4'><strong className='text-blue-dark'>Patient</strong> 2002828 
            <strong className='ms-3 text-blue-dark'>Impatient</strong></div>
            <div className='risk-count px-4 py-1'><strong className='text-blue-dark'>Risk Factors </strong> 04 </div>
            <div className='alllergies-count px-4'><strong className='text-blue-dark'>Allergies</strong> 04 </div>
        </div>
    </Col>
  )
}

export default CardImageViewItem