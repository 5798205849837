import React from 'react'
import Filter from '../assets/images/filter-icon.svg'
import FeatherIcon from 'feather-icons-react';
const SideFilter = ({filters}) => {
  return (
    <div className='side-filter py-4'>
      <h6 className='fw-bold py-2 text-dark'>Filter</h6>
      <FeatherIcon icon="filter" className="my-3" />
      <ul>
          {filters && filters?.map((filter, index) => (
            <li key={index} className='fs-6 align-items-center'><i className="fa-solid fa-arrow-up-short-wide"></i> <span>{filter._fltvarassgntexts[0].FilterIdDesc}</span></li>            
          ))}        
      </ul>
    </div>
  )
}

export default SideFilter
