import React from 'react'
import TableView from '../component/TableView'
import CardView from '../component/CardView'
import CardImageView from '../component/CardImageView'
import PatientRoom from '../component/PatientRoom'
import FloorRoom from "../component/FloorRoom"
import ContactList from "../component/SearchList.js";
import DesignFloorEdit from '../component/DesignFloorEdit.js'

const Dashboard = (props) => {
  const isMobile = window.innerWidth < 992;
  const SelectedTab = () => {
    if(props.tableview === 'A' && !isMobile){
      return <TableView selectedBoard={props.selectedBoard} metaTableCols={props.metaTableCols} contacts={props.contacts} viewcreate={props.viewcreate} viewCreateHandler={props.viewCreateHandler} />
    }
    else if(props.tableview === 'B'){
      return <CardImageView />
    }
    else if(props.tableview === 'C'){
      return <PatientRoom />
    }
    else if(props.tableview === 'D'){
      return <DesignFloorEdit disable={props.disable} setDisable={props.setDisable} toggleSave={props.toggleSave}/>
    }
    else if(props.tableview === 'E'){
      return <CardView />
    }
    else{
      return <CardImageView />
    }
    // switch(props.tableview){
    //     case 'A':
    //         return <TableView />
    //     case 'B':
    //         return <CardImageView />
    //     case 'C':
    //         return <PatientRoom />
    //     case "D":
    //     return <FloorRoom floorSide={props.floorSide}/>
    //     case "E":
    //     return <CardView />
    //     default:
    //         return <TableView />
    // }
}

  return (
    <div>
      {SelectedTab()}
      {/* <TableView />      
        {props.smallCard ? <CardView /> : <CardImageView /> } 
      <PatientRoom /> */}
      
      {/* <h1>Searchable List</h1>
      <h2>Search: </h2>
      <ContactList /> */}
    </div>
  )
}

export default Dashboard