import React from 'react'
import { Row, Container } from 'react-bootstrap'
import CardImageViewItem from './CardImageViewItem';
 

const CardImageView = () => {
  return (
    <div className='card-view-block'>
        <Container>
            <Row className="align-items-center">
                <CardImageViewItem />
                <CardImageViewItem />
                <CardImageViewItem />
                <CardImageViewItem />
                <CardImageViewItem />
                <CardImageViewItem />
                <CardImageViewItem />
                <CardImageViewItem />
                <CardImageViewItem />
                <CardImageViewItem />
                <CardImageViewItem />
                <CardImageViewItem />
            </Row>
        </Container>
    </div>
  )
}

export default CardImageView
