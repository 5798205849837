import React, { useState, useEffect } from "react";
import ReactFlagsSelect from "react-flags-select";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Dropdown } from "react-bootstrap";

import FeatherIcon from "feather-icons-react";
import UserImage from "../assets/images/user-img.png";
import { Link } from "react-router-dom";
import { client } from "../axios";
import { useDispatch, useSelector } from "react-redux";
import { getMainMenu } from "../redux/slice/MenuSlice";
import { getBoardData } from "../redux/slice/boardSlice";
import { getMetaData } from "../redux/slice/MetaDataSlice";
import Loader from "./Loader";

const Header = (props) => {
  const [selected, setSelected] = useState("US");
  const [menuList, setMenuList] = useState();
  const [xmlData, setXmlData] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const { metadatalist } = useSelector((state) => state.metadata);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBoardData());
    dispatch(getMainMenu());
  }, []);
  const { status, error, boardData } = useSelector((state) => state.board);
  const { mainMenu } = useSelector((state) => state.menu);

  const handleToggle = (isOpen) => {
    setShowDropdown(isOpen);
  };

  const handleClose = () => {
    setShowDropdown(false);
  };

  useEffect(() => {
    const data =
      mainMenu &&
      mainMenu.map((menu) => {
        let modelServURL = "";
        if (menu._BoardMenuAssgn[0] !== undefined) {
          const filteredBoardData =
            boardData &&
            boardData.filter((board) => {
              return board.BoardId === menu._BoardMenuAssgn[0].BoardId;
            });
          modelServURL = filteredBoardData; // [0].ModelServ || '';
          // if(modelServURL!=='') {
          //   const parsedUrl = new URL(modelServURL);
          //   modelServURL = parsedUrl.pathname;
          // }
        }
        return {
          ...menu,
          modelServURL,
        };
      });
    setMenuList(data);
  }, [mainMenu, boardData]);

  const buildTree = (parentId) => (item) => {
    const children = menuList.filter(
      (child) => child.ParentMenuId === item.MenuId
    );
    return {
      ...item,
      ...(children.length > 0 && {
        children: children.map(buildTree(item.MenuId)),
      }),
    };
  };

  const nestedData = {
    items:
      menuList &&
      menuList.filter((item) => !item.ParentMenuId).map(buildTree(undefined)),
  };

  const renderMenu = (menu) => {
    if (menu.children && menu.children.length > 0) {
      return (
        <NavDropdown
          title={menu._BoardMenuDescr[0].MenuIdDesc}
          id="basic-nav-dropdown"
          key={menu._BoardMenuDescr[0].MenuIdDesc}
        >
          {menu.children.map(renderMenu)}
        </NavDropdown>
      );
    } else {
      return (
        <Nav.Link
          as={Link}
          to="/"
          onClick={() => {
            props.modelServHandler(menu.modelServURL)
            props.handleChange("A")
          }}
          data-modelURL={menu.modelServURL}
          key={menu.MenuId}
        >
          {menu._BoardMenuDescr[0].MenuIdDesc}
        </Nav.Link>
      );
    }
  };

  if (status === "loading" || status === "Idle") {
    return <Loader />;
  }

  return (
    <div className="header bg-white">
      <div className="header-left px-1">
        <Navbar expand="lg">
          <Container>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="border-0"
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <div className="responsive-view pt-4 pb-1 menu-title d-flex justify-content-between">
                <h3 className="text-dark pt-2">Menu</h3>
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  aria-label="close"
                  className="border-0"
                >
                  <FeatherIcon
                    icon="x"
                    className="ms-auto me-2 text-dark"
                    style={{ width: "24px" }}
                  />
                </Navbar.Toggle>
              </div>

              <Nav className="me-auto">
                {nestedData.items && nestedData.items.map(renderMenu)}
                {/* {  
              nestedData.items && nestedData.items  ?.map((cat) => {
                return (
                  cat.children && cat.children.length > 0 ? (
                    <NavDropdown title={cat._BoardMenuDescr[0].MenuIdDesc} id="basic-nav-dropdown" key={cat._BoardMenuDescr[0].MenuIdDesc}>
                      {
                        cat.children.map((subcat) => {
                          return (
                            subcat.children && subcat.children.length > 0 ? (
                              <NavDropdown title={subcat._BoardMenuDescr[0].MenuIdDesc} id="basic-nav-dropdown" key={subcat._BoardMenuDescr[0].MenuIdDesc}>
                                {
                                  subcat.children.map((subcatchild) => {
                                    return <NavDropdown.Item key={subcatchild.MenuId}>{subcatchild._BoardMenuDescr[0].MenuIdDesc}</NavDropdown.Item>       
                                  })
                                }
                              </NavDropdown>
                            ) 
                            : <NavDropdown.Item key={subcat.MenuId}>{subcat._BoardMenuDescr[0].MenuIdDesc}</NavDropdown.Item> 
                          )
                        })
                      }
                    </NavDropdown>                    
                  ) : <Nav.Link as={Link} key={cat.MenuId}>{cat._BoardMenuDescr[0].MenuIdDesc}</Nav.Link>
                )
              })
            }                               */}
              </Nav>
              {/* <button className="language-btn mobile-language-btn btn bg-transparent px-2 me-2 responsive-view" aria-label="Default select example">
           <img src={EnglishFlag} alt='' className='me-2' />English   
           <FeatherIcon icon="chevron-down" className='ms-auto me-2' style={{width:"20px"}} />       
        </button> */}
              <div className="responsive-view">
                <ReactFlagsSelect
                  selected={selected}
                  onSelect={(code) => setSelected(code)}
                  countries={["US", "GB", "FR", "DE", "IT"]}
                  customLabels={{
                    US: "English",
                    GB: "EN-GB",
                    FR: "FR",
                    DE: "DE",
                    IT: "IT",
                  }}
                  placeholder="Select Language"
                />
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="header-right ms-auto">
        {/* <button className="language-btn btn bg-transparent px-2 me-2" aria-label="Default select example">
           <img src={EnglishFlag} alt='' className='me-2' />English   
           <FeatherIcon icon="chevron-down" className='ms-auto me-2' style={{width:"20px"}} />       
        </button> */}
        <div className="language-btn">
          <ReactFlagsSelect
            selected={selected}
            onSelect={(code) => setSelected(code)}
            countries={["US", "GB", "FR", "DE", "IT"]}
            customLabels={{
              US: "English",
              GB: "EN-GB",
              FR: "FR",
              DE: "DE",
              IT: "IT",
            }}
            placeholder="Select Language"
          />
        </div>
        <div className="header-user d-flex align-items-center">
          <Dropdown show={showDropdown} onToggle={handleToggle}>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="d-flex bg-light border-0 text-start"
            >
              <img src={UserImage} alt="" className="rounded-circle" />
              <div className="user-name ps-2">
                <h3 className="p-0 m-0 fs-6 text-dark">Austin Robertson</h3>
                <p className="p-0 m-0 lh-1">Doctor</p>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1" onClick={handleClose}>
                <FeatherIcon icon="user" />
                Profile
              </Dropdown.Item>
              {/* <Dropdown.Item href="#/action-1" onClick={props.handleFloorSide} className="desktop-view"><FeatherIcon icon="layers" />Design Floor</Dropdown.Item> */}
              <Link
                className="dropdown-item"
                onClick={() => {
                  // props.handlehidecontrols();
                  props.handleChange("D")
                  handleClose();
                }}
                to="/designflooredit"
              >
                <FeatherIcon icon="layers" />
                Design Floor
              </Link>
              {/* <Dropdown.Item href="#/action-1"><FeatherIcon icon="sliders" />Theme Color Setting</Dropdown.Item> */}
              <Dropdown.Item href="#/action-1" onClick={handleClose}>
                <FeatherIcon icon="log-out" />
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Header;