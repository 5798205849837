import { useState} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import Steps from '../Steps/Steps'
import Step1 from '../Steps/Step1'
import Step2 from '../Steps/Step2'
import Step3 from '../Steps/Step3'
import FeatherIcon from 'feather-icons-react';
import Step4 from '../Steps/Step4'
import { useDispatch } from "react-redux";
import { addPatientData } from "../../redux/slice/addPatientSlice";
import Loader from "../Loader";

const AddPatient = (props) => {

  const [step,setStep] = useState(1);
  const [showLoader,setShowLoader] = useState(false);

  const dispatch = useDispatch()

  // const [data,setData] = useState({
  //   firstName: "",
  //   lastName: "",
  //   dateOfBirth:"",
  //   sex: "",
  //   nationality: "",
  //   streetNo: "",
  //   country: "",
  //   telephone: "",
  //   cell: "",
  //   email_id: "",
  //   maritalStatus: "",
  //   language: "",
  //   religion: "",
  //   cityOfBirth: "",
  //   birthPlace: "",
  //   caseType: "",
  //   visitType: "",
  //   status: "",
  //   treatOu: "",
  //   deptOu: "",
  //   treatRoom: "",
  //   bed: ""
  // })
  const [data,setData] = useState({
    "@odata.metadataEtag" : "W/\"20240509065208\"",
    "CorrespondenceLanguage" : "EN",
    "FormOfAddress" : "0002",
    "GenderCodeName" : "2",
    "Language" : "EN",
    "BirthDate" : "1997-05-05",
    "BusPartMaritalStatus" : "2",
    "BusPartNationality" : "AE",
    "_Address" : [
      {
        "ValidityStartDate" : "2024-05-05T00:00:00Z",
        "ValidityEndDate" : "9999-12-31T23:59:59Z",
        "AddressTimeZone" : "UTC+4",
        "CityName" : "wert",
        "Country" : "AE",
        "District" : "",
        "Building" : "",
        "RoomNumber" : "",
        "Floor" : "",
        "Language" : "",
        "POBox" : "",
        "PostalCode" : "567",
        "Region" : "",
        "StreetName" : "ghj",
        "_EmailAddress" : [
          {
            "EmailAddress" :"abc@mail.com"
          }
        ],
        "_FaxNumber" : [
          {
            "FaxCountry" : "AE",
            "FaxNumber" : "989898",
            "FaxNumberExtension" : ""
          }
        ],
        "_MobilePhoneNumber" : [
          {
            "DestinationLocationCountry" : "AE",
            "PhoneNumber" : "9345549334",
            "PhoneNumberExtension" : "",
            "InternationalPhoneNumber" : "+9719345549334"
          }
        ],
        "_PhoneNumber" : [
          {
            "DestinationLocationCountry" : "AE",
            "PhoneNumber" : "2345549334",
            "PhoneNumberExtension" : "",
            "InternationalPhoneNumber" : "+9712345549334"
          }
        ]
      }
    ],
    "_BuPaIdentif" : [
      {
        "BPIdentificationType" : "FS0001",
        "BPIdentificationNumber" : "AE12345",
        "BPIdnNmbrIssuingInstitute" : "",
        "BPIdentificationEntryDate" : "2024-04-01",
        "Country" : "AE",
        "Region" : "",
        "ValidityStartDate" : "2024-04-01",
        "ValidityEndDate" : "2024-12-31"
      }
    ],
    "_Customer" : {
      "Customer" : "1",
      "AlternativePayerAccount" : "",
      "AlternativePayeeIsAllowed" : false,
      "PaymentReason" : ""
    },
    "_Patient" : {
      "Inactive" : false,
      "_Name" : [
        {
          "PatientId" : "0000000000",
          "NameUse" : "2",
          "Spras" : "EN",
          "DispName" : false,
          "FullName" : "",
          "LastName" : "DummyLSTNAME",
          "MiddleName" : "",
          "FirstName" : "",
          "NamePrefix" : "",
          "NameSuffix" : "",
          "NamePeriod" : null
        }
      ]
    }
  })

  const handleFormTab = (step) => {
    setStep(step);
   }
  
  const makeRequest = (formdata) => {
    console.log("form submitted", formdata)
    setShowLoader(true) 
    dispatch(addPatientData(formdata))
    .then((res) => {
      props.addPatientClose()
      setShowLoader(false)
    })
  } 
  const handleNextStep = (newData, final = false) => {
    console.log("newdata", newData)
    setData(prev => ({...prev, ...newData}))
    if(final) {      
      makeRequest(newData)
      return
    }
    setStep(step + 1);
  }

  const handlePrevStep = (newData) => {
    setData(prev => ({...prev, ...newData}))
    setStep(step - 1);
  }
    

    const handleInputData = input => e => {
      const {value } = e.target;
  
      setData(prevState => ({
        ...prevState,
        [input]: value
    }));
  }
  switch (step) {
    case 1:
      return (
        <>        
          <Modal show={props.show} onHide={props.addPatientClose} className='add-patient-popup'>
        <Modal.Header>
          <Modal.Title className='text-light'>Add Patient</Modal.Title>
          {/* <div className="ms-auto me-3 bottom-btn-patient">
              <Button type="submit" className='btn-danger ms-auto me-3' variant="primary" onClick={handleNextStep}>
                Next
              </Button>
            </div> */}
          <Button variant="secondary" onClick={props.addPatientClose} className='bg-transparent shadow-none border-0 pe-0 pt-0'>
            X
          </Button>
        </Modal.Header>
          <Modal.Body>
            <div className='add-patient-step-form'>
              <div className='add-patient-steps pb-3'>
                <ul>
                  <li className='text-dark py-2 active-form-step'  onClick={()=>{handleFormTab(1)}}>Patient</li>
                  <li role="button" className='text-dark py-2' onClick={()=>{handleFormTab(2)}}>Occupational/Economic History</li>
                  <li role="button" className='text-dark py-2' onClick={()=>{handleFormTab(3)}}>Visit Data</li>
                  <li role="button" className='text-dark py-2' onClick={()=>{handleFormTab(4)}}>Case Data</li>
                  <li role="button" className='text-dark py-2' onClick={()=>{handleFormTab(5)}}>Insurance Relationship</li>
                </ul>
              </div>
             <Steps next={handleNextStep} handleFormData={handleInputData} data={data}/>
            </div>
          </Modal.Body>
         
      </Modal>
        </>
      );
    
    case 2:
      return (
        <Modal show={props.show} onHide={props.addPatientClose} className='add-patient-popup'>
        <Modal.Header>
          <Modal.Title className='text-light'>Add Patient</Modal.Title>
          {/* <div className="ms-auto me-3 bottom-btn-patient">
            <Button className='btn-danger bg-primary text-light border-light me-3' variant="primary" onClick={handlePrevStep}>
              Previous
            </Button>
            <Button className='btn-danger' variant="primary" onClick={handleNextStep}>
              Next
            </Button>
          </div>           */}
          <Button variant="secondary" onClick={props.addPatientClose} className='bg-transparent shadow-none border-0 pe-0 pt-0'>
            X
          </Button>
        </Modal.Header>
          <Modal.Body>
            <div className='add-patient-step-form'>
              <div className='add-patient-steps pb-3'>
                <ul>
                  <li role="button" className='text-dark py-2' onClick={()=>{handleFormTab(1)}}>Patient</li>
                  <li className='text-dark py-2  active-form-step' onClick={()=>{handleFormTab(2)}}>Occupational/Economic History</li>
                  <li role="button" className='text-dark py-2' onClick={()=>{handleFormTab(3)}}>Visit Data</li>
                  <li role="button" className='text-dark py-2' onClick={()=>{handleFormTab(4)}}>Case Data</li>
                  <li role="button" className='text-dark py-2' onClick={()=>{handleFormTab(5)}}>Insurance Relationship</li>
                </ul>
              </div>
             <Step1 next={handleNextStep} prev={handlePrevStep} handleFormData={handleInputData} data={data}/>
            </div>
          </Modal.Body>
         
      </Modal>
      );
    
    case 3:
      return (
        <Modal show={props.show} onHide={props.addPatientClose} className='add-patient-popup'>
        <Modal.Header>
          <Modal.Title className='text-light'>Add Patient</Modal.Title>
          {/* <div className="ms-auto me-3 bottom-btn-patient">
            <Button className='btn-danger bg-primary text-light border-light me-3' variant="primary" onClick={handlePrevStep}>
              Previous
            </Button>
            <Button className='btn-danger' variant="primary" onClick={handleNextStep}>
              Next
            </Button>
          </div> */}
          <Button variant="secondary" onClick={props.addPatientClose} className='bg-transparent shadow-none border-0 pe-0 pt-0'>
            X
          </Button>
        </Modal.Header>
          <Modal.Body>
            <div className='add-patient-step-form'>
              <div className='add-patient-steps pb-3'>
                <ul>
                  <li role="button" className='text-dark py-2' onClick={()=>{handleFormTab(1)}}>Patient</li>
                  <li role="button" className='text-dark py-2' onClick={()=>{handleFormTab(2)}}>Occupational/Economic History</li>
                  <li className='text-dark py-2  active-form-step' onClick={()=>{handleFormTab(3)}}>Visit Data</li>
                  <li role="button" className='text-dark py-2' onClick={()=>{handleFormTab(4)}}>Case Data</li>
                  <li role="button" className='text-dark py-2' onClick={()=>{handleFormTab(5)}}>Insurance Relationship</li>
                </ul>
              </div>
             <Step2 next={handleNextStep} prev={handlePrevStep} handleFormData={handleInputData} data={data}/>
            </div>
          </Modal.Body>
         
      </Modal>
      );
      
      case 4:
      return (
        <Modal show={props.show} onHide={props.addPatientClose} className='add-patient-popup'>
        <Modal.Header>
          <Modal.Title className='text-light'>Add Patient</Modal.Title>
          {/* <div className="ms-auto me-3 bottom-btn-patient">
            <Button className='btn-danger bg-primary text-light border-light me-3' variant="primary" onClick={handlePrevStep}>
              Previous
            </Button>
            <Button className='btn-danger' variant="primary" onClick={handleNextStep}>
              Next
            </Button>
          </div> */}
          <Button variant="secondary" onClick={props.addPatientClose} className='bg-transparent shadow-none border-0 pe-0 pt-0'>
            X
          </Button>
        </Modal.Header>
          <Modal.Body>
            <div className='add-patient-step-form'>
              <div className='add-patient-steps pb-3'>
                <ul>
                  <li role="button" className='text-dark py-2' onClick={()=>{handleFormTab(1)}}>Patient</li>
                  <li role="button" className='text-dark py-2' onClick={()=>{handleFormTab(2)}}>Occupational/Economic History</li>
                  <li role="button" className='text-dark py-2' onClick={()=>{handleFormTab(3)}}>Visit Data</li>
                  <li className='text-dark py-2 active-form-step' onClick={()=>{handleFormTab(4)}}>Case Data</li>
                  <li role="button" className='text-dark py-2' onClick={()=>{handleFormTab(5)}}>Insurance Relationship</li>
                </ul>
              </div>
             <Step3 next={handleNextStep} prev={handlePrevStep} handleFormData={handleInputData} data={data}/>
            </div>
          </Modal.Body>
         
      </Modal>
      );

      case 5:
      return (
        <>
        <Modal show={props.show} onHide={props.addPatientClose} className='add-patient-popup'>
        <Modal.Header>
          <Modal.Title className='text-light'>Add Patient</Modal.Title>
          {/* <div className="ms-auto me-3 bottom-btn-patient">
            <Button className='btn-danger bg-primary text-light border-light me-3' variant="primary" onClick={handlePrevStep}>
              Previous
            </Button>
            <Button className='btn-danger' variant="primary" onClick={handleNextStep}>
            <FeatherIcon icon="save" className='mx-2' />Save
            </Button>
          </div> */}
          <Button variant="secondary" onClick={props.addPatientClose} className='bg-transparent shadow-none border-0 pe-0 pt-0'>
            X
          </Button>
        </Modal.Header>
          <Modal.Body>
            <div className='add-patient-step-form'>
              <div className='add-patient-steps pb-3'>
                <ul>
                  <li role="button" className='text-dark py-2' onClick={()=>{handleFormTab(1)}}>Patient</li>
                  <li role="button" className='text-dark py-2' onClick={()=>{handleFormTab(2)}}>Occupational/Economic History</li>
                  <li role="button" className='text-dark py-2' onClick={()=>{handleFormTab(3)}}>Visit Data</li>
                  <li role="button" className='text-dark py-2' onClick={()=>{handleFormTab(4)}}>Case Data</li>
                  <li className='text-dark py-2 active-form-step' onClick={()=>{handleFormTab(5)}}>Insurance Relationship</li>
                </ul>
              </div>
             <Step4 next={handleNextStep} prev={handlePrevStep} handleFormData={handleInputData} data={data}/>
            </div>
          </Modal.Body>
         
      </Modal>
      { showLoader ? <Loader /> : null }
          </>
      );
      
  }
 
}

export default AddPatient
