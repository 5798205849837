import React, { useState, useEffect } from "react";
import AddIcon from '../assets/images/add-icon.svg'
import CreateIcon from '../assets/images/create-icon.svg'
import CreateCharge from '../assets/images/create-charge-icon.svg'
import Filter from '../assets/images/filter-icon.svg'
import AddPatient from './Modals/AddPatient';
import xmlJs from 'xml-js';

const SideMenu = ({actionToDisplay}) => {
  const [PatientShow, setPatientShow] = useState(false);
 

  const addPatientClose = () => setPatientShow(false);
  const addPatientShow = () => setPatientShow(true);
 
  return (
    <>
      <div className='side-menu py-4 bg-light'>
        <ul>
          {actionToDisplay && actionToDisplay?.map((action, index) => (
            action.ActionId === 'AddPatient' ? (
              <li key={index} className='fs-6 align-items-center' onClick={addPatientShow}><i className="fa-regular fa-square-plus"></i> <span>{action.SysLangActionIdDesc}</span></li>
            ) : <li key={index} className='fs-6 align-items-center'><i className="fa-regular fa-square-plus"></i> <span>{action.SysLangActionIdDesc}</span></li>
            
          ))}
        </ul>
      </div>
      {/* <div className='side-menu py-4 bg-light'>
        <ul>
          <li className='fs-6 align-items-center' style={{cursor:"pointer"}}  onClick={addPatientShow}><i className="fa-regular fa-square-plus"></i> Add Patient</li>
          <li className='fs-6 align-items-center' style={{cursor:"pointer"}}> <i className="fa-solid fa-user-plus"></i> Create Absence</li>
          <li className='fs-6 align-items-center'> <i className="fa-regular fa-file-lines"></i> Create Charge</li>
          <li className='fs-6 align-items-center'> <i className="fa-solid fa-arrow-up-short-wide"></i> Create Consultation</li>
          <li className='fs-6 align-items-center'> <i className="fa-solid fa-user-plus"></i> Create Transport Order</li>
          <li className='fs-6 align-items-center'> <i className="fa-regular fa-square-plus"></i> Create Transport Order without Patient</li>
        </ul>        
      </div> */}
      <AddPatient show={PatientShow} addPatientShow={addPatientShow} addPatientClose={addPatientClose} />
    </>
    
  )
}

export default SideMenu
