import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ChangeRowColPopup = (props) => {
  return (
    <Modal
      show={props.showPopup}
      onHide={!props.disabled ? props.onClose : undefined}
      className="add-patient-popup create-absence-popup"
    >
      <Modal.Header>
        <Modal.Title className="text-light">Change Rows and Cols</Modal.Title>
        <Button
          variant="secondary"
          onClick={props.onClose}
          className="bg-transparent shadow-none border-0 pe-0 pt-0"
        >
          X
        </Button>
      </Modal.Header>
      <Modal.Body>
        <div className="test">
          Your data maybe lost, do you still want to change the rows and columns
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={props.disabled} onClick={props.onClose}>
          Cancel
        </Button>
        <Button disabled={props.disabled} onClick={props.onSubmit}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeRowColPopup;