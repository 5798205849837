import axios from 'axios';

export const client = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`
});

client.interceptors.request.use(
    (config) => {
        // Add basic authentication headers
        config.auth = {
            username: 'zaptech',
            password: 'Zaptech@123',
        };
        config.headers['Content-Type'] = 'application/json';
        config.headers['Cache-Control'] = 'no-cache';
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
