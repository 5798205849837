import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../axios";

const initialState = {
  status: "Idle",
  error: "",
  inPatientData: "",
  hospitalPlanRowColData: "",
  floorView: [],
  occupiedBeds: [],
  patientDetails: [],
};

export const getInPatientFloor = createAsyncThunk(
  "getInPatientData/floor",
  async () => {
    const response = await client.get(`/inPatient`);
    return response.data.value;
  }
);

export const getInHospitalPlan = createAsyncThunk(
  "setInHospitalPlan",
  async (postData) => {
    const response = await client.post(`/inHospitalPlan`, postData);
    return response.data.value;
  }
);

export const modifyInHospitalPlan = createAsyncThunk(
  "modifyInHospitalPlan",
  async (postData) => {
    const response = await client.patch(`/inHospitalPlan`, postData);
    return response.data.value;
  }
);

export const getInHospitalPlanRowColDetails = createAsyncThunk(
  "hospitalFloorplanDetails",
  async (postData) => {
    const response = await client.get(`/hospitalFloorplanDetails`);
    return response.data.value;
  }
);

export const getFloorPlanView = createAsyncThunk(
  "getFloorPlanView",
  async () => {
    const response = await client.get(`/getHospitolPlanView`);
    return response.data.value;
  }
);
export const getOccupiedBeds = createAsyncThunk("getOccupiedBeds", async () => {
  const response = await client.get(`/getBedDetails`);
  return response.data.value;
});
export const getPatientDetails = createAsyncThunk(
  "getPatientDetails",
  async () => {
    const response = await client.get(`/getPatientDetails`);
    return response.data.value;
  }
);

export const setHospitalPlanView = createAsyncThunk(
  "setHospitalPlanView",
  async (postData) => {
    const response = await client.post(`/setHospitalPlanView`, postData);
    return response.data.value;
  }
);

export const inPatientSlice = createSlice({
  name: "inPatient",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInPatientFloor.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getInPatientFloor.fulfilled, (state, action) => {
        state.status = "true";
        state.inPatientData = action.payload;
        state.error = "";
      })
      .addCase(getInPatientFloor.rejected, (state, action) => {
        state.status = "false";
        state.inPatientData = "";
        state.error = action.error.message;
      })
      .addCase(getInHospitalPlanRowColDetails.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getInHospitalPlanRowColDetails.fulfilled, (state, action) => {
        state.status = "true";
        state.hospitalPlanRowColData = action.payload;
        state.error = "";
      })
      .addCase(getInHospitalPlanRowColDetails.rejected, (state, action) => {
        state.status = "false";
        state.hospitalPlanRowColData = "";
        state.error = action.error.message;
      })
      .addCase(getInHospitalPlan.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getInHospitalPlan.rejected, (state, action) => {
        state.status = "false";
        state.error = action.error.message;
      })
      .addCase(modifyInHospitalPlan.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(modifyInHospitalPlan.rejected, (state, action) => {
        state.status = "false";
        state.error = action.error.message;
      })
      .addCase(setHospitalPlanView.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(setHospitalPlanView.rejected, (state, action) => {
        state.status = "false";
        state.error = action.error.message;
      })
      .addCase(getFloorPlanView.rejected, (state, action) => {
        state.status = "false";
        state.error = action.error.message;
      })
      .addCase(getFloorPlanView.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getFloorPlanView.fulfilled, (state, action) => {
        state.status = "true";
        state.floorView = action.payload;
        state.error = "";
      })
      .addCase(getOccupiedBeds.rejected, (state, action) => {
        state.status = "false";
        state.error = action.error.message;
      })
      .addCase(getOccupiedBeds.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getOccupiedBeds.fulfilled, (state, action) => {
        state.status = "true";
        state.occupiedBeds = action.payload;
        state.error = "";
      })
      .addCase(getPatientDetails.rejected, (state, action) => {
        state.status = "false";
        state.error = action.error.message;
      })
      .addCase(getPatientDetails.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getPatientDetails.fulfilled, (state, action) => {
        state.status = "true";
        state.patientDetails = action.payload;
        state.error = "";
      });
  },
});

export default inPatientSlice.reducer;