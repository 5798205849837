import React from 'react'
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";

const Steps = (props) => {
  const handleSubmit = (values) => {
    props.next(values)
  }
  return (
    <>
    <Formik initialValues={props.data} onSubmit={handleSubmit}>
      {(formprops) => (
        <Form>
          <h3 className='text-dark py-2'>Occupational/Economic History</h3>
          <div className='row mb-2'>
            <div className='col-md-6 field-group'>
              <input className="form-control" id="First Name"></input>
              <label for="First Name" className="form-label">Say</label>
            </div>
            <div className='col-md-6 field-group'>
              <input className="form-control" id="Last Name"></input>
              <label for="Last Name" className="form-label">Nothing</label>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-md-4 field-group'>
              <input className="form-control" id="Sex"></input>
              <label for="Sex" className="form-label">Fine</label>
            </div>
            <div className='col-md-4 field-group'>
              <input className="form-control" id="Nationality"></input>
              <label for="Nationality" className="form-label">Good</label>
            </div>
          </div>
          <div className='formtopbtns'>
            <Button className='btn-danger bg-primary text-light border-light me-3' variant="primary" onClick={() => props.prev(formprops.values)}>Previous</Button>
            <Button type="submit" className='btn-danger' variant="primary">Next</Button>
          </div>
        </Form>
      )}
    </Formik>
    
    </>
  )
}

export default Steps