import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../axios";

const initialState = {
    status: 'Idle',
    error: '',
    layoutcols: '',
    layoutdata: ''
}

export const getLayoutCols = createAsyncThunk(
  'getLayoutCols/metadata',
  async () => {
    const response = await client.get(`/layoutVarCols`)
    //return response.data.value[0]._LytVarAssgn
    return response.data.value
  }
)

export const getLayoutColsData = createAsyncThunk(
  'getLayoutColsData/metadata',
  async (data) => {
    const response = await client.get(`/layoutVarData?url=${data.modelServURL}&modelURLres=${data.resurl}`)
    return response.data.value
  }
)

export const layoutVariantSlice = createSlice({
    name: 'layoutvariant',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
        .addCase(getLayoutCols.pending, (state) => {
          state.status = 'loading'
          state.error = ''
        })
        .addCase(getLayoutCols.fulfilled, (state, action) => {
          state.status = 'true'
          state.layoutcols = action.payload
          state.error = ''
        })
        .addCase(getLayoutCols.rejected, (state, action) => {
          state.status = 'false'
          state.layoutcols = ''
          state.error = action.error.message
        })
        .addCase(getLayoutColsData.pending, (state) => {
          state.status = 'loading'
          state.error = ''
        })
        .addCase(getLayoutColsData.fulfilled, (state, action) => {
          state.status = 'true'
          state.layoutdata = action.payload
          state.error = ''
        })
        .addCase(getLayoutColsData.rejected, (state, action) => {
          state.status = 'false'
          state.layoutdata = ''
          state.error = action.error.message
        })
    }
})

export default layoutVariantSlice.reducer