import React, { useState, useEffect } from "react";
import RoomBoxItem from "./RoomBoxItem";
import DesignFloorRoomItem from "./DesignFloorRoomItem";
import { Button } from "react-bootstrap";
import useGetInPatientData from "../hooks/useGetInPatientData";
import ChangeRowColPopup from "./Modals/ChangeRowCols";
const DesignFloorEdit = (props) => {
  const {
    inPatientUnitOptions,
    handleSetSelectedUnit,
    selectedUnit,
    roomsArray: roomArr,
    handleSaveRowCols,
    rowColData,
    FloorArray,
    setFloorArray,
    onSubmit,
    showPopup,
    onClose,
    shouldShowPopup,
    disabled,
    ef,
    setEf,
    isChanged,
    beds
  } = useGetInPatientData(props?.toggleSave, props.setDisable, props.disable);

  const [roomArray, setRoomArray] = useState([]);
  const [assignBedId, setAssignBedId] = useState();
  const [drop, setDrop] = useState(false);

  useEffect(() => {
    setRoomArray(roomArr);
  }, [roomArr]);

  const onDragStart = (evt) => {
    let element = evt.currentTarget;

    element.classList.add("dragged");
    evt.dataTransfer.setData("text/plain", evt.currentTarget.id);
    evt.dataTransfer.effectAllowed = "move";
    const tt = evt.dataTransfer.setData("text/plain", evt.currentTarget.id);
  };

  const onDragEnd = (evt) => {
    evt.currentTarget.classList.remove("dragged");
  };

  const onDragEnter = (evt) => {
    evt.preventDefault();
    let element = evt.currentTarget;
    element.classList.add("dragged-over");
    evt.dataTransfer.dropEffect = "move";
    setAssignBedId(evt.currentTarget.id);
  };

  const onDragLeave = (evt) => {
    let currentTarget = evt.currentTarget;
    let newTarget = evt.relatedTarget;

    if (newTarget.parentNode === currentTarget || newTarget === currentTarget)
      return;
    evt.preventDefault();
    let element = evt.currentTarget;
    element.classList.remove("dragged-over");
  };

  const onDragOver = (evt) => {
    evt.preventDefault();
    evt.dataTransfer.dropEffect = "move";
  };

  const [changing, setChanging] = useState(true);
  const onDrop = (evt, value) => {
    // evt.preventDefault();
    // evt.stopPropagation()
    setDrop(true);
    setChanging((prev) => !prev);
    evt.currentTarget.classList.remove("dragged-over");
    let data = evt.dataTransfer.getData("text/plain");

    if (data && data.split(".")[0] === "left-section") {
      if (assignBedId.split(".")[0] !== "left-section" || data === assignBedId)
        return;

      const [, rowIndex, columnId] = data.split(".").map(Number);
      const [, secRowIndex, secColumnId] = assignBedId.split(".").map(Number);

      let updated = FloorArray.map((arr, index) => {
        if (index === rowIndex && index === secRowIndex) {
          return arr.map((item) => {
            if (item.id === columnId) {
              item.room = "empty";
              item.done = false;
              item.FloorId = "";
            }
            if (item.id === secColumnId) {
              item.room = "fill";
            }
            return item;
          });
        }
        if (index === rowIndex) {
          return arr.map((item) => {
            if (item.id === columnId) {
              item.room = "empty";
              item.done = false;
              item.FloorId = "";
            }
            return item;
          });
        }
        if (index === secRowIndex) {
          return arr.map((item) => {
            if (item.id === secColumnId) {
              item.room = "fill";
            }
            return item;
          });
        }
        return arr;
      });
      setFloorArray([...updated]);

      let TRoomArray = roomArray;

      const itemIndex = TRoomArray.findIndex(
        (item) => item.FloorId === `${secRowIndex}.${secColumnId}`
      );

      if (itemIndex >= 0) {
        TRoomArray[itemIndex].room = "empty";
        TRoomArray[itemIndex].FloorId = "";
        if (TRoomArray[itemIndex].modified) {
          TRoomArray[itemIndex].deleted = true;
        }
      }
      let beUpdated = TRoomArray.map((task) => {
        // console.log(task.id, "<====================")

        if (task.FloorId === `${rowIndex}.${columnId}`) {
          task.room = "fill";
          task.done = value;
          task.FloorId = `${secRowIndex}.${secColumnId}`;
          task.deleted = false;
        }
        return task;
      });

      // item.room = "empty";
      // item.FloorId = "";
      // if (item.modified) {
      //   item.deleted = true;
      // }

      setRoomArray(beUpdated);

      return;
    }
    const [, rowIndex, ColumnId] = assignBedId.split(".").map(Number);
    data = parseInt(data);

    if (!assignBedId.includes("left-section")) return;
    let TRoomArray = roomArray;

    const itemIndex = TRoomArray.findIndex(
      (item) => `left-section.${item.FloorId}` === assignBedId
    );

    if (itemIndex >= 0) {
      TRoomArray[itemIndex].room = "empty";
      TRoomArray[itemIndex].FloorId = "";
      if (TRoomArray[itemIndex].modified) {
        TRoomArray[itemIndex].deleted = true;
      }
    }
    let updated = TRoomArray.map((task) => {
      if (task.id === data) {
        task.room = "fill";
        task.done = value;
        task.FloorId = `${rowIndex}.${ColumnId}`;
        task.deleted = false;
      }
      return task;
    });
    setRoomArray(updated);

    let TFloorArray = FloorArray;
    // managing by rowIndex and columnId
    console.log(rowIndex, ColumnId);
    let bedupdated = TFloorArray.map((task, index) => {
      if (index === rowIndex) {
        // console.log("came here ====>", rowIndex);
        return task.map((taskObj) => {
          if (taskObj.id === ColumnId) {
            taskObj.room = "fill";
            //task.done = value;
            //task.bedId = AssignBedId;
          }
          //console.log("task", task)
          return taskObj;
        });
      }
      return task;
    });

    setFloorArray(bedupdated);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDrop(false);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [changing]);

  var scale = ef.scale,
    panning = ef.panning,
    pointX = ef.pointX,
    pointY = ef.pointY,
    start = ef.start;
  var zoom = document.getElementById("zoom");
  const setTransform = (dv) => {
    dv.style.transform =
      "translate(" + pointX + "px, " + pointY + "px) scale(" + scale + ")";
  };

  useEffect(() => {
    zoom = document.getElementById("zoom");
    // function setTransform() {
    //   zoom.style.transform =
    //     "translate(" + pointX + "px, " + pointY + "px) scale(" + scale + ")";
    // }

    zoom.onmousedown = function (e) {
      // e.preventDefault();
      // e.stopPropagation();
      //e.target.closest(".empty").classList.add("prevent-room-popup");
      start = { x: e.clientX - pointX, y: e.clientY - pointY };
      // setDrop(false);
      panning = true;
    };

    document.onmouseup = function (e) {
      // e.preventDefault();
      // e.stopPropagation();
      //e.target.classList.remove("prevent-room-popup");
      panning = false;
    };

    zoom.onmousemove = function (e) {
      // e.preventDefault();
      // e.stopPropagation();
      if (drop) {
        return;
      }
      if (!panning) {
        return;
      }
      pointX = e.clientX - start.x;
      pointY = e.clientY - start.y;
      console.log(scale, "-------I am called");
      setTransform(zoom);
    };

    // zoom.onwheel = function (e) {
    //   // e.preventDefault();

    //   var xs = (e.clientX - pointX) / scale,
    //     ys = (e.clientY - pointY) / scale,
    //     delta = e.wheelDelta ? e.wheelDelta : -e.deltaY;
    //   delta > 0 ? (scale *= 1.2) : (scale /= 1.2);
    //   pointX = e.clientX - xs * scale;
    //   pointY = e.clientY - ys * scale;

    //   setTransform(zoom);
    // };

    return () => {
      // setEf((prev) => ({
      //   ...prev,
      //   scale,
      //   pointX,
      //   pointY,
      // }));
    };

    /*end  zoom */
  }, [zoom, drop, isChanged, selectedUnit]);

  const removeRoom = (itemid, id) => {
    const [rowIndex, columnId] = id.split(".").map(Number);
    let updated = FloorArray.map((arr, index) => {
      if (index === rowIndex) {
        return arr.map((item) => {
          if (item.id === columnId) {
            item.room = "empty";
            item.done = false;
            item.FloorId = "";
          }
          return item;
        });
      }
      return arr;
    });
    setFloorArray([...updated]);

    let bedupdated = roomArray.map((item) => {
      if (item.id === itemid) {
        item.room = "empty";
        item.FloorId = "";
        if (item.modified) {
          item.deleted = true;
        }
      }
      return item;
    });
    setRoomArray([...bedupdated]);
  };

  // console.log("FloorArray", FloorArray);
  // console.log("RoomArray", roomArray);
  const zoomin = (rd) => {
    scale = scale / rd;
    if (zoom) setTransform(zoom);
  };
  const zoomout = (rd) => {
    scale = scale * rd;
    if (zoom) setTransform(zoom);
  };

  const resultsRenderCol = [];

  for (var j = 0; j < FloorArray.length; j++) {
    const resultsRender = [];
    for (var i = 0; i < FloorArray[j].length; i += 100) {
      resultsRender.push(
        <div className="floor-room-row d-flex">
          {FloorArray[j].slice(i, i + 100).map((item) => {
            return (
              <DesignFloorRoomItem
                id={`${j}.${item.id}`}
                key={item.id}
                onDragLeave={onDragLeave}
                room={item.room}
                floorId={item.floorId}
                removeRoom={removeRoom}
                RoomArray={roomArray}
                onDragEnter={onDragEnter}
                onDragEnd={onDragEnd}
                onDragOver={onDragOver}
                onDrop={onDrop}
                onDragStart={onDragStart}
                isEditable={props.isEditable}
                beds={beds}
              />
            );
          })}
        </div>
      );
    }
    resultsRenderCol.push(resultsRender);
  }

  return (
    <div className="d-flex w-100 p-3">
      <ChangeRowColPopup
        disabled={disabled}
        showPopup={showPopup}
        onClose={onClose}
        onSubmit={onSubmit}
      />

      <div
        id="editor"
        style={{ overflowY: "hidden", overflowX: "scroll", width: "100%" }}
      >
        <div id="zoom" className="floor-room-edit-left wid">
          {resultsRenderCol}
          {/* <div className='floor-room-row d-flex'> 
           {
            FloorArray && FloorArray.map((item)=>{
             return <DesignFloorRoomItem id={item.id} key={item.id} onDragLeave={onDragLeave}
             room={item.room}
             floorId={item.floorId}
             RoomArray={RoomArray}
             onDragEnter={onDragEnter}
             onDragEnd={onDragEnd}
             onDragOver={onDragOver}
             onDrop={onDrop} />   
            })

           }
         </div>
              */}
        </div>
      </div>
      <div className="design-floor">
        {selectedUnit ? (
          <div className="w-100 pb-3">
            <Button
              variant="primary"
              size="sm"
              className="me-2"
              onClick={() => zoomout(2)}
            >
              Zoom In
            </Button>
            <Button
              className="me-2"
              variant="primary"
              size="sm"
              onClick={() => zoomin(2)}
            >
              Zoom Out
            </Button>
          </div>
        ) : null}

        <div className="otf-box bg-light d-flex flex-wrap p-3 mb-3 rounded add-patient-step-form">
          <select
            className="form-select"
            id="inPatientUnit"
            name="inPatientUnit"
            value={selectedUnit}
            onChange={(e) => handleSetSelectedUnit(e.target.value)}
          >
            <option selected value={""}>
              Select InPatient Unit
            </option>
            {inPatientUnitOptions?.map((opt) => (
              <option value={opt} key={opt}>
                {opt}
              </option>
            ))}
          </select>
          {/* <h3 className="fs-5 text-dark w-100">OTF (0)</h3>
                <div className="radio-box opacity-50 text-dark"><input className="form-check-input border-dark rounded-circle me-2" style={{width:"12px", height:"12px"}} type="checkbox" id="flexCheckDefault" value="" /><label className="form-check-label me-2" for="flexCheckDefault">Floor 01</label></div>
                <div className="radio-box opacity-50 text-dark"><input className="form-check-input border-dark rounded-circle me-2" style={{width:"12px", height:"12px"}} type="checkbox" id="flexCheckDefault" value="" /><label className="form-check-label me-2" for="flexCheckDefault">Floor 02</label></div>
                <div className="radio-box opacity-50 text-dark"><input className="form-check-input border-dark rounded-circle me-2" style={{width:"12px", height:"12px"}} type="checkbox" id="flexCheckDefault" value="" /><label className="form-check-label me-2" for="flexCheckDefault">Floor 03</label></div>
                <div className="radio-box opacity-50 text-dark"><input className="form-check-input border-dark rounded-circle me-2" style={{width:"12px", height:"12px"}} type="checkbox" id="flexCheckDefault" value="" /><label className="form-check-label me-2" for="flexCheckDefault">Floor 04</label></div>
                <div className="radio-box opacity-50 text-dark"><input className="form-check-input border-dark rounded-circle me-2" style={{width:"12px", height:"12px"}} type="checkbox" id="flexCheckDefault" value="" /><label className="form-check-label me-2" for="flexCheckDefault">Floor 05</label></div>
                <div className="radio-box opacity-50 text-dark"><input className="form-check-input border-dark rounded-circle me-2" style={{width:"12px", height:"12px"}} type="checkbox" id="flexCheckDefault" value="" /><label className="form-check-label me-2" for="flexCheckDefault">Floor 06</label></div> */}
        </div>
        {selectedUnit && props.isEditable ? (
          <div className="otf-box bg-light d-flex flex-wrap p-3 mb-3 rounded add-patient-step-form">
            <div className="row mb-2">
              <div className="col-md-6 field-group">
                <label for="rows" className="form-label">
                  Rows
                </label>
                <input
                  min={1}
                  type="number"
                  className={`form-control`}
                  name="rows"
                  onChange={handleSaveRowCols}
                  value={rowColData.rows}
                  onPaste={(e) => e.preventDefault()}
                />
              </div>
              <div className="col-md-6 field-group">
                <label for="cols" className="form-label">
                  Columns
                </label>
                <input
                  type="number"
                  className={`form-control`}
                  name="cols"
                  onChange={handleSaveRowCols}
                  min={1}
                  value={rowColData.cols}
                  onPaste={(e) => e.preventDefault()}
                />
              </div>
            </div>
            <div className="row mt-2 flex-fill">
              <div className="col-md-12 text-end">
                <Button
                  disabled={disabled}
                  variant="primary"
                  size="sm"
                  onClick={shouldShowPopup ? onClose : onSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
            {/* <h3 className="fs-5 text-dark w-100">Expected (0)</h3>
                <div className="radio-box opacity-50 text-dark"><input className="form-check-input border-dark rounded-circle me-2" style={{width:"12px", height:"12px"}} type="checkbox" id="flexCheckDefault" value="" /><label className="form-check-label me-2" for="flexCheckDefault">Floor 01</label></div>
                <div className="radio-box opacity-50 text-dark"><input className="form-check-input border-dark rounded-circle me-2" style={{width:"12px", height:"12px"}} type="checkbox" id="flexCheckDefault" value="" /><label className="form-check-label me-2" for="flexCheckDefault">Floor 02</label></div>
                <div className="radio-box opacity-50 text-dark"><input className="form-check-input border-dark rounded-circle me-2" style={{width:"12px", height:"12px"}} type="checkbox" id="flexCheckDefault" value="" /><label className="form-check-label me-2" for="flexCheckDefault">Floor 03</label></div>
                <div className="radio-box opacity-50 text-dark"><input className="form-check-input border-dark rounded-circle me-2" style={{width:"12px", height:"12px"}} type="checkbox" id="flexCheckDefault" value="" /><label className="form-check-label me-2" for="flexCheckDefault">Floor 04</label></div>
                <div className="radio-box opacity-50 text-dark"><input className="form-check-input border-dark rounded-circle me-2" style={{width:"12px", height:"12px"}} type="checkbox" id="flexCheckDefault" value="" /><label className="form-check-label me-2" for="flexCheckDefault">Floor 05</label></div>
                <div className="radio-box opacity-50 text-dark"><input className="form-check-input border-dark rounded-circle me-2" style={{width:"12px", height:"12px"}} type="checkbox" id="flexCheckDefault" value="" /><label className="form-check-label me-2" for="flexCheckDefault">Floor 06</label></div> */}
          </div>
        ) : null}
        {props.isEditable ? (
          <div className="otf-box bg-light d-flex flex-wrap p-3 mb-3 rounded">
            <h3 className="fs-5 text-dark w-100">Rooms</h3>
            <div className="floor-waiting-box">
              {roomArray &&
                roomArray?.map((item) => {
                  return (
                    <div
                      id={item.id}
                      onDragLeave={(e) => onDragLeave(e)}
                      onDragEnter={(e) => onDragEnter(e)}
                      onDragEnd={(e) => onDragEnd(e)}
                      onDragOver={(e) => onDragOver(e)}
                      onDrop={(e) => onDrop(e, false)}
                    >
                      <RoomBoxItem
                        key={item.id}
                        id={item.id}
                        name={item.name}
                        bed={item.room}
                        RoomArray={roomArray}
                        onDragStart={onDragStart}
                        onDragEnd={onDragEnd}
                        roomName={item.roomName}
                        showRoomName="true"
                        isEditable={props.isEditable}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        ) : null}
        <div className="waiting-box"></div>
      </div>
    </div>
  );
};

export default DesignFloorEdit;