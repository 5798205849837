import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../axios";

const initialState = {
    status: 'Idle',
    error: '',
    actionVariants: '',
    filterVariants: '',
}

export const getActionVariant = createAsyncThunk(
  'getActionVariant/sideMenu',
  async () => {
    const response = await client.get(`/actions`)
    return response.data.value
}
)
export const getFilterVariant = createAsyncThunk(
  'getFilterVariant/sideMenu',
  async () => {
    const response = await client.get(`/filters`)
    return response.data.value
}
)


export const sideMenuSlice = createSlice({
    name: 'sideMenu',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
        .addCase(getActionVariant.pending, (state) => {
          state.status = 'loading'
          state.error = ''
        })
        .addCase(getActionVariant.fulfilled, (state, action) => {
          state.status = 'true'
          state.actionVariants = action.payload
          state.error = ''
        })
        .addCase(getActionVariant.rejected, (state, action) => {
          state.status = 'false'
          state.actionVariants = ''
          state.error = action.error.message
        })
        .addCase(getFilterVariant.pending, (state) => {
          state.status = 'loading'
          state.error = ''
        })
        .addCase(getFilterVariant.fulfilled, (state, action) => {
          state.status = 'true'
          state.filterVariants = action.payload
          state.error = ''
        })
        .addCase(getFilterVariant.rejected, (state, action) => {
          state.status = 'false'
          state.filterVariants = ''
          state.error = action.error.message
        })
    }
})

export default sideMenuSlice.reducer