import React, { useEffect, useState } from 'react'
import { Col, Row, Container } from 'react-bootstrap'; 
import PatientRoomItems from './PatientRoomItems';
import BedBoxItem from './BedBoxItem';
import PatientInformationForm from './Modals/PatientInformationForm'


const PatientRoom = () => {    

    const NPatientList = [
        {   id:1,
            name: "Patient 01", 
            age: "32",
            gender: "male",
            bed: "empty",
            done: false,
            bedId: ""
        },
        {   id:2,
            name: "Patient 02", 
            age: "34",
            gender: "female",
            bed: "empty",
            done: false,
            bedId: ""
        },
        {   id:3,
            name: "Patient 03", 
            age: "36",
            gender: "male",
            bed: "empty",
            done: false,
            bedId: ""
        },
        {   id:4,
            name: "Patient 04", 
            age: "36",
            gender: "male",
            bed: "empty",
            done: false,
            bedId: ""
        },      
        {   id:5,
            name: "Patient 05", 
            age: "36",
            gender: "male",
            bed: "empty",
            done: false,
            bedId: ""
        },        
        {   id:6,
          name: "Patient 06", 
          age: "36",
          gender: "male",
          bed: "empty",
          done: false,
          bedId: ""
        },  
        {   id:7,
            name: "Patient 07", 
            age: "36",
            gender: "male",
            bed: "empty",
            done: false,
            bedId: ""
        },
        {   id:8,
            name: "Patient 08", 
            age: "36",
            gender: "male",
            bed: "empty",
            done: false,
            bedId: ""
          },
          {   id:9,
            name: "Patient 09", 
            age: "36",
            gender: "male",
            bed: "empty",
            done: false,
            bedId: ""
          }        
        
    ]
    const NBedArray = [
        {   id:1,
            bed: "empty",
            BedName: "bed1"
        },
        {  id:2,
            bed: "empty",
            BedName: "bed2"
        },
        {   id:3,
            bed: "empty",
            BedName: "bed3"
        },
        {   id:4,
          bed: "empty",
          BedName: "bed4"
        },
        {   id:5,
            bed: "empty",
            BedName: "bed5"
        },
        {   id:6,
            bed: "empty",
            BedName: "bed6"
        },
        {   id:7,
            bed: "empty",
            BedName: "bed7"
        },
        {   id:8,
            bed: "empty",
            BedName: "bed8"
        },
        {   id:9,
          bed: "empty",
          BedName: "bed9"
        }
      ]
    const [PatientList, setPatientList] = useState(NPatientList); 
    const [AssignBedId, setAssignBedId] = useState(); 
    const [BedArray, setBedArray] = useState(NBedArray); 
    const [show, setShow] = useState(false);
    const [pdata, setPdata] = useState('')
    const [dropValue, setDropValue] = useState('')
    const [popupInfo, setPopupInfo] = useState('')
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
     
    const onDragStart = evt => {
        let element = evt.currentTarget;
        element.classList.add("dragged");
        evt.dataTransfer.setData("text/plain", evt.currentTarget.id);
        evt.dataTransfer.effectAllowed = "move";
        const tt = evt.dataTransfer.setData('text/plain', evt.currentTarget.id);
       
      };
    const onDragEnd = evt => {
        evt.currentTarget.classList.remove("dragged");
      };
      const onDragEnter = evt => {
        evt.preventDefault();
        let element = evt.currentTarget;
        element.classList.add("dragged-over");
        evt.dataTransfer.dropEffect = "move";
        console.log("patient room evernt", evt.currentTarget)
        setAssignBedId(evt.currentTarget.id);
      };
    const onDragLeave = evt => {
        let currentTarget = evt.currentTarget;
        let newTarget = evt.relatedTarget;
        if (newTarget.parentNode === currentTarget || newTarget === currentTarget)
          return;
        evt.preventDefault();
        let element = evt.currentTarget;
        element.classList.remove("dragged-over");
      };
    const onDragOver = evt => {
        evt.preventDefault();
        evt.dataTransfer.dropEffect = "move";
      };
    const onDrop = (evt, value) => {
        evt.preventDefault();
        evt.currentTarget.classList.remove("dragged-over");
        let data = evt.dataTransfer.getData("text/plain");
        data = parseInt(data)
        setPdata(data)
        setDropValue(value)
        let TPatientList = PatientList;
        const tt = TPatientList.filter((item) => item.id === parseInt(data))
        setPopupInfo(tt)
        handleShow()
      };

      const confirmHandler = () => {
        let TPatientList = PatientList;
        const itemIndex = TPatientList.findIndex(
          (item) => item.bedId === AssignBedId
        )
        if(itemIndex >= 0) {
            alert("bed is already fill")
        }
        else {
          let updated = TPatientList.map(task => {
            if (task.id === pdata) {     
              task.bed = "fill";
              task.done = dropValue;
              task.bedId = AssignBedId;            
            }                  
            return task;
          });
          //setPatientList(updated);
        }        

        let TBedArray = BedArray;
         
        let bedupdated = TBedArray.map(task => {
          if (task.id === parseInt(AssignBedId)) {     
            task.bed = "fill";
            //task.done = value;
           //task.bedId = AssignBedId;
          } 
          return task;
        });
        //setBedArray(bedupdated);
        handleClose()
    }
      
      
      useEffect(()=>{
        setPatientList(PatientList)
        setBedArray(BedArray)
      },[PatientList, BedArray])

      const resultsRender = [];

      const removePatient = (itemid, id) => {
        let updated = PatientList.map(item => {
          if (item.id === itemid) {     
            item.bed = "empty";
            item.done = false;
            item.bedId = '';            
          }                  
          return item;
        });
        setPatientList(updated);

        let bedupdated = BedArray.map(item => {
          if (item.id === id) {     
            item.bed = "empty";
          } 
          return item;
        });
        setBedArray(bedupdated);
      }

    for (var i = 0; i < BedArray.length; i += 3) {
      resultsRender.push(
        <Col md={4} key={i}>
        <div className='patient-room-item bg-light rounded position-relative p-0 overflow-hidden mb-4 p-3 d-flex flex-wrap'>
          {BedArray.slice(i, i + 3).map((item) =>  
          {
            return <BedBoxItem 
              PatientList={PatientList} 
              bed={item.bed}                                
              id={item.id}
              key={item.id}                          
              onDragLeave={onDragLeave}
              onDragEnter={onDragEnter}
              onDragEnd={onDragEnd}
              onDragOver={onDragOver}
              onDrop={onDrop}
              removePatient={removePatient}
              />
          }
          )}
        </div>
        </Col>
      );
    }

    

    let BedPending  = PatientList.filter(t => !t.done);
    //let BedDone = PatientList.filter(t => t.done);
  return (
    <div className='patient-rooms-block p-2'>
        <ul className='d-flex'>
            <li className='d-flex text-light rounded flex-column text-center active'>Inn. Station 1 <span>4 of 11 Bed Occupied</span></li>
            <li className='d-flex text-light rounded flex-column text-center'>Inn. Station 2 <span>26 of 38 Bed Occupied</span></li>
            <li className='d-flex text-light rounded flex-column text-center'>Chi. Station 1 <span>2 of 21 Bed Occupied</span></li>   
            <li className='d-flex text-light rounded flex-column text-center'>Chi. Station 6 <span>1 of 8 Bed Occupied</span></li>        
        </ul>
        <div className='room-bed'>
            <Container>
                <Row>
                  {resultsRender}                                                
                </Row>
            </Container>
        </div>
        <h3 className='p-3 py-4 text-dark fw-bold'>Patients without Room</h3>
        <div className='patient-without-room p-2'>
          <Container>
                 
                    <div
                        className='row'
                         onDragLeave={e => onDragLeave(e)}
                         onDragEnter={e => onDragEnter(e)}
                         onDragEnd={e => onDragEnd(e)}
                         onDragOver={e => onDragOver(e)}
                         onDrop={e => onDrop(e, false)}
                    >
                {
                    BedPending && BedPending?.map((item)=>{
                    return <PatientRoomItems key={item.id} id={item.id} name={item.name} age={item.age} gender={item.gender} bed={item.bed} 
                    
                    onDragStart = {onDragStart}
                    onDragEnd = {onDragEnd}
                    />
                    
                    })     
                }
                    
                    </div>
                
            </Container>
        </div>
        <PatientInformationForm AssignBedId={AssignBedId} popupInfo={popupInfo} show={show} confirmHandler={confirmHandler} handleClose={handleClose} />
    </div>
  )
}

export default PatientRoom
