import React from 'react';
// Input component for Edm.String type
const DateTimeOffsetInput = ({ name }) => (
    <input
      type="datetime-local"
      className="form-control"
      name={name}
      id={name}
      placeholder={name}
    />
  );

export default DateTimeOffsetInput;
