import React, { useEffect, useState } from "react";
import SidePanel from "./SidePanel";
import Header from "./Header";
import Search from "./Search";
import Controls from "./Controls";
import FeatherIcon from "feather-icons-react";
import Dashboard from "../container/Dashboard";
import SidePanelDesktop from "./SidePanelDesktop";
import ContactName from "./Contact.json";
import DesignFloorEdit from "./DesignFloorEdit";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMetaData, getModelServRes } from "../redux/slice/MetaDataSlice";
import {
  getActionVariant,
  getFilterVariant,
} from "../redux/slice/sideMenuSlice";
import { getLayoutColsData } from "../redux/slice/layoutVariantSlice";
import Loader from "./Loader";

const Layout = () => {
  const { metadatalist, modelservres } = useSelector((state) => state.metadata);
  const dispatch = useDispatch();
  const { status, error, actionVariants } = useSelector(
    (state) => state.sideMenu
  );
  const [actionVariantState, setActionVariantState] = useState(null);
  const [actionToDisplay, setActionToDisplay] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState();
  const [filterToDisplay, setFilterToDisplay] = useState(null);
  const [searchResUrl, setSearchResUrl] = useState(null);
  const [modelServURL, setModelServURL] = useState(null);
  const [disable, setDisable] = useState(false);

  // useEffect(() => {
  //   dispatch(getMetaData())
  // }, [dispatch])

  const metaActions =
    metadatalist?.["edmx:Edmx"]?.["edmx:DataServices"]?.Schema?.Action || [];
  const metaFilters =
    metadatalist?.["edmx:Edmx"]?.["edmx:DataServices"]?.Schema?.Function || [];
  const metaTableCols =
    metadatalist?.["edmx:Edmx"]?.["edmx:DataServices"]?.Schema?.EntityType
      .Property || [];

  const modelServHandler = (menuData) => {
    setSelectedBoard(menuData[0]);
    let modelServURL = menuData[0].ModelServ || "";
    if (modelServURL !== "") {
      const parsedUrl = new URL(modelServURL);
      modelServURL = parsedUrl.pathname;
      setModelServURL(modelServURL);
    }
    dispatch(getMetaData(modelServURL)).then((metares) => {
      dispatch(getActionVariant()).then((res) => {
        const actionVariantsData =
          res &&
          res?.payload?.filter((actionData) => {
            return (
              actionData.ActVariantUuid ===
              menuData[0]._BoardVariants["ActVariantUuid"]
            );
          });
        setActionVariantState(actionVariantsData);
        const actionToDisplayData =
          actionVariantsData &&
          actionVariantsData[0]?._ActVarAssgn.filter((o1) =>
            metares?.payload?.["edmx:Edmx"]?.[
              "edmx:DataServices"
            ]?.Schema?.Action?.some(
              (o2) => o1._ActVarAssgnDescr[0].ActionId === o2._attributes?.Name
            )
          );
        setActionToDisplay(actionToDisplayData);
      });
      dispatch(getFilterVariant()).then((res) => {
        const filterVariantsData =
          res &&
          res?.payload?.filter((filterData) => {
            return (
              filterData.FltVariantUuid ===
              menuData[0]._BoardVariants["FltVariantUuid"]
            );
          });
        const filterToDisplayData =
          filterVariantsData &&
          filterVariantsData[0]?._fltvarasn.filter((o1) =>
            metares?.payload?.["edmx:Edmx"]?.[
              "edmx:DataServices"
            ]?.Schema?.Function?.some(
              (o2) => o1._fltvarassgntexts[0].FilterId === o2._attributes?.Name
            )
          );
        setFilterToDisplay(filterToDisplayData);
      });
      dispatch(getModelServRes(modelServURL)).then((res) => {
        const resurl = res && res?.payload[0]?.url;
        setSearchResUrl(resurl);
        const data = {
          modelServURL: modelServURL,
          resurl: resurl,
        };
        dispatch(getLayoutColsData(data));
      });
    });
  };

  const isMobile = window.innerWidth < 992;
  const [smallCard, setSmallCard] = useState(false);
  const [viewcreate, setViewcreate] = useState(false);
  const [hidecontrols, setHidecontrols] = useState(false);
  const viewCreateHandler = () => {
    setViewcreate(true);
  };

  const [tableview, setTableview] = useState(() => (!isMobile ? "A" : "B"));

  const smallCardView = () => {
    setSmallCard(true);
  };

  const handleChange = (values) => {
    //alert(JSON.stringify(values) );
    //do something with the values
    setTableview(values);
    setfloorSide(false);
  };

  const [sideMenu, setsideMenu] = useState(false);
  const [sideFilter, setsideFilter] = useState(false);
  const [floorSide, setfloorSide] = useState(false);
  const [toggleSave, setToggleSave] = useState(null);

  const [designfloorToggled, setdesignfloorToggled] = useState(false);

  const handleSideMenu = () => {
    setsideMenu(!sideMenu);
  };
  const handleSideFilter = () => {
    setsideFilter(!sideFilter);
  };
  const handlehidecontrols = () => {
    setHidecontrols(true);
    setTableview("");
  };
  const handleshowcontrols = () => {
    setHidecontrols(false);
    setTableview("A");
  };
  const handleshowview = () => {
    setTableview("A");
  };

  const handleFloorSide = () => {
    setfloorSide(true);
    setdesignfloorToggled(true);
  };

  // if(status === 'loading' || status === 'Idle' || metaTableCols.length === 0) {
  //   return <Loader />
  // }
  if (status === "loading") {
    return <Loader />;
  }
  return (
    <div className={floorSide ? "d-flex floordesign-show" : "d-flex"}>
      {isMobile ? (
        <SidePanel
          sideMenu={sideMenu}
          sideFilter={sideFilter}
          handleSideMenu={handleSideMenu}
          handleSideFilter={handleSideFilter}
          actionToDisplay={actionToDisplay}
          filterToDisplay={filterToDisplay}
        />
      ) : (
        <SidePanelDesktop
          handleshowcontrols={handleshowcontrols}
          handleshowview={handleshowview}
          actionToDisplay={actionToDisplay}
          filterToDisplay={filterToDisplay}
        />
      )}
      <div className="right-content flex-fill">
        <Header
          modelServHandler={modelServHandler}
          handleFloorSide={handleFloorSide}
          handlehidecontrols={handlehidecontrols}
          handleChange={handleChange}
        />
        <Search
          modelServURL={modelServURL}
          searchResUrl={searchResUrl}
          selectedBoard={selectedBoard}
          tableview={tableview}
          floorSide={floorSide}
          hidecontrols={hidecontrols}
          viewcreate={viewcreate}
          setToggleSave={setToggleSave}
          disable={disable}
        />
        {!hidecontrols ? (
          <Controls
            smallCardView={smallCardView}
            handleChange={handleChange}
            tableview={tableview}
            hidecontrols={hidecontrols}
          />
        ) : (
          ""
        )}
        <Routes>
          <Route
            path="/"
            exact
            element={
              <Dashboard
                selectedBoard={selectedBoard}
                metaTableCols={metaTableCols}
                metadatalist={metadatalist}
                smallCard={smallCard}
                tableview={tableview}
                floorSide={floorSide}
                viewcreate={viewcreate}
                viewCreateHandler={viewCreateHandler}
                disable={disable}
                setDisable={setDisable}
                toggleSave={toggleSave}
              />
            }
          />
          <Route
            path="/designflooredit"
            exact
            element={
              <DesignFloorEdit
                disable={disable}
                setDisable={setDisable}
                toggleSave={toggleSave}
                isEditable={true}
              />
            }
          />
        </Routes>
        <div
          className="mobile-action-filter-btn p-4 position-fixed m-auto left-0 right-0 w-100 d-flex justify-content-between responsive-view"
          style={{ bottom: "10px" }}
        >
          <div
            className="btn btn-danger fw-semibold me-3 w-50"
            onClick={handleSideMenu}
          >
            <FeatherIcon icon="plus-circle" className="pe-2" />
            Action
          </div>
          <div
            className="btn fw-semibold border border-dark w-50"
            style={{ background: "#E9ECEF" }}
            onClick={handleSideFilter}
          >
            <FeatherIcon icon="filter" className="pe-2" />
            Filter
          </div>
          {/* <div className='btn btn-danger fw-semibold me-3 w-50' ><FeatherIcon  icon="plus-circle" className="pe-2" />Action</div>
              <div className='btn fw-semibold border border-dark w-50' style={{background:"#E9ECEF"}} ><FeatherIcon  icon="filter" className="pe-2" />Filter</div> */}
        </div>
      </div>
    </div>
  );
};

export default Layout;