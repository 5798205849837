import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInPatientFloor,
  getInHospitalPlan,
  getInHospitalPlanRowColDetails,
  modifyInHospitalPlan,
  setHospitalPlanView,
  getFloorPlanView,
  getOccupiedBeds,
  getPatientDetails,
} from "../redux/slice/inPatientSlice";
import toast from "react-hot-toast";

const NFloorArray = [];

const useGetInPatientData = (toggleSave, setDisable, disabled) => {
  const { inPatientData, floorView, error, occupiedBeds, patientDetails } =
    useSelector((state) => state.inPatient);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [floorArray, setFloorArray] = useState([NFloorArray]);
  const [roomsArray, setRoomsArray] = useState([]);
  const [beds, setBeds] = useState([]);
  const dispatch = useDispatch();
  const [rowColData, setRowColData] = useState({
    rows: 1,
    cols: 1,
  });

  const [ef, setEf] = useState({
    scale: 1,
    panning: false,
    pointX: 0,
    pointY: 0,
    start: { x: 0, y: 0 },
  });

  useEffect(() => {
    if (!selectedUnit) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [selectedUnit]);

  const calSize = () => {
    // The elements we need to use and our current scale
    var zoom = document.getElementById("zoom");
    var editor = document.getElementById("editor");
    var scale = 1;

    if (!zoom || !editor) return;
    // Reset the initial scale and style incase we are resizing the page
    zoom.classList.add("loading");
    zoom.style.transform = "scale(1)";

    // Loop until the scale is small enough to fit it's container
    while (
      editor.getBoundingClientRect().width <
        zoom.getBoundingClientRect().width &&
      scale > 0 // This is just incase even at 0.05 scale it doesn't fit, at which point this would cause an infinate loop if we didn't have this check
    ) {
      // Reduce the scale
      scale -= 0.05;
      // Apply the new scale
      // zoom.style.transform = "scale(" + scale + ")";
      zoom.style.transform =
        "translate(" + 0 + "px, " + 0 + "px) scale(" + scale + ")";
      zoom.style.transformOrigin = "0 0";
    }
    // Display the final result
    setEf((prev) => ({ ...prev, scale }));
    setIsChanged((prev) => !prev);
    zoom.classList.remove("loading");
  };

  const [isChanged, setIsChanged] = useState(null);

  const generateRoomsArray = useCallback(
    async (isInitial) => {
      // console.log("came until here", inPatientData, selectedUnit);
      if (!inPatientData || !selectedUnit) return [];

      const iuData = inPatientData?.filter((obj) => obj.OBJCAT === "IU");
      const id = iuData.find((obj) => obj.STEXT === selectedUnit)?.OBJID;

      if (!id) return [];

      let arr = inPatientData
        ?.filter((obj) => obj.OBJCAT === "RO" && obj.PARID === id)
        .map((obj, index) => {
          return {
            OBJID: obj?.OBJID,
            OTYPE: obj?.OTYPE,
            id: index,
            name: obj.STEXT,
            roomName: obj.STEXT,
            room: "empty",
          };
        });

      if (floorView) {
        arr = arr.map((obj) => {
          const el = floorView.findLast((ob) => ob.OrgObjid === obj.OBJID);
          if (el) {
            const floorId = `${el?.RoomPosRow}.${el?.RoomPosColumn}`;
            return { ...obj, FloorId: floorId, modified: true, room: "fill" };
          }
          return obj;
        });
      }

      const selectedObj = (inPatientData ?? [])?.find(
        (obj) => obj?.OBJCAT === "IU" && obj?.STEXT === selectedUnit
      );

      const newArr = (floorView ?? []).filter(
        (obj) => obj.OrgObjid === selectedObj?.OBJID
      );

      const obj = newArr[0];
      setRowColData({
        rows: parseInt(obj?.FloorSizeRows) || 1,
        cols: parseInt(obj?.FloorSizeColumns) || 1,
      });

      handleChangeLayout(obj?.FloorSizeRows, obj?.FloorSizeColumns);
      setFloorArray((prev) => {
        let flArr = JSON.parse(JSON.stringify(prev));

        arr.map((obj) => {
          if (obj.FloorId) {
            // console.log(obj.FloorId.split("."));
            const [row, col] = obj.FloorId.split(".");
            if (flArr[row] && flArr[row][col]) {
              flArr[row][col].room = "fill";
            }
          }
        });
        return flArr;
      });
      setRoomsArray(arr);
    },
    [
      dispatch,
      floorView,
      inPatientData,
      rowColData.cols,
      rowColData.rows,
      selectedUnit,
    ]
  );

  useEffect(() => {
    console.log("Came here =======>", toggleSave);
    if (toggleSave === null) {
      return;
    }

    // Call this function on click of save button
    const submit = async () => {
      try {
        setDisable(true);
        const arr = roomsArray
          ?.map((obj) => {
            if (obj.FloorId) {
              const splt = obj.FloorId.split(".").map(Number);
              return {
                OrgObjid: obj?.OBJID,
                Orgtype: obj?.OTYPE,
                RoomPosColumn: splt[1].toString(),
                RoomPosRow: splt[0].toString(),
                FloorSizeColumns: "",
                FloorSizeRows: "",
                modified: obj.modified,
              };
            }
            if (obj.deleted) {
              return {
                OrgObjid: obj?.OBJID,
                Orgtype: obj?.OTYPE,
                deleted: true,
              };
            }
            return false;
          })
          .filter(Boolean);
        if (!arr.length) return;
        await dispatch(setHospitalPlanView(arr));
        await dispatch(getFloorPlanView());
        setDisable(false);
        if (error) {
          toast.error(error);
        }
        toast.success("view updated successfully");
      } catch (error) {
        console.error("Cought an error");
      }
    };

    submit();
  }, [toggleSave]);

  useEffect(() => {
    dispatch(getInPatientFloor());
    dispatch(getInHospitalPlanRowColDetails());
    dispatch(getFloorPlanView());
    dispatch(getOccupiedBeds());
    dispatch(getPatientDetails());
  }, [dispatch]);

  useEffect(() => {
    if (inPatientData) {
      const dt = inPatientData?.filter((obj) => obj?.OBJCAT === "BD");
      setBeds(dt);
    }
  }, [inPatientData]);

  useEffect(() => {
    generateRoomsArray();
    setTimeout(() => {
      calSize();
    }, 0);
  }, [inPatientData, selectedUnit, floorView]);

  const inPatientUnitOptions = useMemo(() => {
    if (!inPatientData) return [];

    return inPatientData
      ?.filter((obj) => obj.OBJCAT === "IU")
      ?.map((obj) => obj.STEXT);
  }, [inPatientData]);

  const handleSetSelectedUnit = (value) => {
    try {
      if (!value) return;
      setSelectedUnit(value);
      // const selectedObj = (inPatientData ?? [])?.find(
      //   (obj) => obj?.OBJCAT === "IU" && obj?.STEXT === value
      // );

      // const arr = (floorView ?? []).filter(
      //   (obj) => obj.OrgObjid === selectedObj?.OBJID
      // );

      // const obj = arr[arr.length - 1 >= 0 ? arr.length - 1 : 0];

      // setRowColData({
      //   rows: parseInt(obj?.FloorSizeRows) || 1,
      //   cols: parseInt(obj?.FloorSizeColumns) || 1,
      // });

      // handleChangeLayout(
      //   parseInt(obj?.FloorSizeRows) || 1,
      //   parseInt(obj?.FloorSizeColumns) || 1
      // );
    } catch (error) {
      console.log("Error while selecting value");
    }
  };

  const handleSaveRowCols = (e) => {
    const { name, value } = e.target;
    setRowColData((prev) => ({ ...prev, [name]: parseInt(value) || 1 }));
  };

  const handleSubmit = async () => {
    try {
      const data = (inPatientData ?? [])?.find(
        (obj) => obj?.OBJCAT === "IU" && obj?.STEXT === selectedUnit
      );

      const selectedObj = (inPatientData ?? [])?.find(
        (obj) => obj?.OBJCAT === "IU" && obj?.STEXT === selectedUnit
      );

      const obj = (floorView ?? []).find(
        (obj) => obj.OrgObjid === selectedObj?.OBJID
      );

      if (!obj) {
        await dispatch(
          getInHospitalPlan({
            OrgObjid: data?.OBJID,
            Orgtype: data?.OTYPE,
            FloorSizeColumns: rowColData.cols.toString(),
            FloorSizeRows: rowColData.rows.toString(),
          })
        );
      } else {
        await dispatch(
          modifyInHospitalPlan({
            OrgObjid: data?.OBJID,
            Orgtype: data?.OTYPE,
            FloorSizeColumns: rowColData.cols.toString(),
            FloorSizeRows: rowColData.rows.toString(),
          })
        );
      }
    } catch (error) {
      console.log(error, "---- Error while submitting form");
    }
  };

  const handleChangeLayout = (rows, cols) => {
    const rowArray = Array.from({ length: rows ?? rowColData.rows }).map(() =>
      Array.from({ length: cols ?? rowColData.cols }).map((_, index) => ({
        id: index,
        room: "empty",
        done: false,
        roomId: "",
      }))
    );
    setFloorArray(rowArray);
  };

  const handleFloorRowColsSave = async () => {
    try {
      setDisable(true);

      // console.log(roomsArray, "----RoomsArray")
      const arr = roomsArray
        ?.map((obj) => {
          if (obj.FloorId) {
            const splt = obj.FloorId.split(".").map(Number);
            // console.log(splt[0], splt[1]);
            // console.log(
            //   splt,
            //   rowColData,
            //   "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<"
            // );
            if (
              splt[0] > rowColData.rows - 1 ||
              splt[1] > rowColData.cols - 1
            ) {
              return {
                OrgObjid: obj?.OBJID,
                Orgtype: obj?.OTYPE,
                deleted: true,
              };
            }
          }
          return false;
        })
        .filter(Boolean);
      if (arr.length) {
        await dispatch(setHospitalPlanView(arr));
      }
      await handleSubmit();
      await dispatch(getFloorPlanView());
      if (error) {
        toast.error(error);
      } else toast.success("Rows and columns are updated successfully");

      setDisable(false);
      setShowPopup(false);
    } catch (error) {
      console.error("Cought an error");
    }
  };

  // popup changes

  const shouldShowPopup = useMemo(() => {
    // console.log(inPatientData, hospitalPlanRowColData);
    const selectedObj = (inPatientData.length ? inPatientData : [])?.find(
      (obj) => obj?.OBJCAT === "IU" && obj?.STEXT === selectedUnit
    );

    const arr = (floorView?.length ? floorView : []).filter(
      (obj) => obj.OrgObjid === selectedObj?.OBJID
    );

    const obj = arr[arr.length - 1 >= 0 ? arr.length - 1 : 0];
    // console.log(
    //   parseInt(obj?.FloorSizeRows) || 1,
    //   rowColData.rows,
    //   parseInt(obj?.FloorSizeColumns) || 1,
    //   rowColData.cols
    // );
    return (
      (parseInt(obj?.FloorSizeRows) || 1) > rowColData.rows ||
      (parseInt(obj?.FloorSizeColumns) || 1) > rowColData.cols
    );
  }, [
    floorView,
    inPatientData,
    rowColData.cols,
    rowColData.rows,
    selectedUnit,
  ]);

  const [showPopup, setShowPopup] = useState(false);
  const onClose = () => {
    setShowPopup((prev) => !prev);
  };

  return {
    inPatientUnitOptions,
    roomsArray,
    selectedUnit,
    handleSetSelectedUnit,
    handleSaveRowCols,
    rowColData,
    handleSubmit,
    FloorArray: floorArray,
    setFloorArray,
    onSubmit: handleFloorRowColsSave,
    showPopup,
    onClose,
    shouldShowPopup,
    disabled,
    ef,
    setEf,
    isChanged,
    beds,
  };
};

export default useGetInPatientData;