import React from 'react'
import { Row, Container } from 'react-bootstrap'
import CardViewItem from './CardViewItem';
 

const CardView = () => {
  return (
    <div className='card-view-block'>
        <Container>
            <Row className="align-items-center">
                <CardViewItem />
                <CardViewItem />
                <CardViewItem />
                <CardViewItem />
                <CardViewItem />
                <CardViewItem />
                <CardViewItem />
                <CardViewItem />
                <CardViewItem />
                <CardViewItem />
                <CardViewItem />
                <CardViewItem />
            </Row>
        </Container>
    </div>
  )
}

export default CardView
