import React from 'react'
import DatePicker from "react-datepicker";
import { useState} from "react";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import dayjs from 'dayjs'

const Steps = (props) => {
    const [startDate, setStartDate] = useState(new Date());
    const handleSubmit = (values) => {
      props.next(values)
    }
  return (
    <>
    <Formik initialValues={props.data} onSubmit={handleSubmit}>
      {(formprops) => (
        <Form>
          <h3 className='text-dark py-2'>Personal Details</h3>
          <div className='row mb-2'>
            <div className='col-md-6 field-group'>
              <input type="text" className={`form-control`} id={`_Patient._Name[0].FirstName`} name={`_Patient._Name[0].FirstName`} onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.FirstName} />
              <label for="FirstName" className="form-label">First Name</label>
            </div>
            <div className='col-md-6 field-group'>
              <input type="text" className={`form-control`} id={`_Patient._Name[0].LastName`} name={`_Patient._Name[0].LastName`} onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.lastName} />
              <label for="lastName" className="form-label">Last Name</label>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-md-4 field-group dob-field'>
                <DatePicker showIcon toggleCalendarOnIconClick selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" />
                <input type="hidden" id="BirthDate" 
                  onChange={
                    (date) => setStartDate(dayjs(date).format('YYYY-MM-DD'))
                    
                  } 
                  name="BirthDate" value={formprops.values.BirthDate = dayjs(startDate).format('YYYY-MM-DD')} />
                <label for="BirthDate" className="form-label">Date of Birth</label>
            </div>
            <div className='col-md-4 field-group'>
              <select className="form-select" id="GenderCodeName" name="GenderCodeName" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.GenderCodeName}>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
              </select>
              <label for="GenderCodeName" className="form-label">Sex</label>
            </div>
            <div className='col-md-4 field-group'>
              <input type="text" className={`form-control`} id="BusPartNationality" name="BusPartNationality" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.BusPartNationality} />
              <label for="BusPartNationality" className="form-label">Nationality</label>
            </div>
          </div>
          <h3 className='text-dark py-2'>Address Details</h3>
          <div className='row mb-2'>
          <div className='col-md-4 field-group'>
              <input type="text" className={`form-control`} id={`_Address[0].StreetName`} name={`_Address[0].StreetName`} onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.StreetName} />
              <label for="streetNo" className="form-label">Street / No</label>
            </div>
            <div className='col-md-4 field-group'>
              <input type="text" className={`form-control`} id={`_Address[0].Country`} name={`_Address[0].Country`} onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.Country} />
              <label for="country" className="form-label">Country Key</label>
            </div>
            <div className='col-md-4 field-group'>
              <input type="text" className={`form-control`} id={`_Address[0]._PhoneNumber[0].PhoneNumber`} name={`_Address[0]._PhoneNumber[0].PhoneNumber`} onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.PhoneNumber} />
              <label for="telephone" className="form-label">Telephone</label>
            </div>
            <div className='col-md-4 field-group'>
              <input type="text" className={`form-control`} id={`_Address[0]._MobilePhoneNumber[0].PhoneNumber`} name={`_Address[0]._MobilePhoneNumber[0].PhoneNumber`} onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.PhoneNumber} />
              <label for="cell" className="form-label">Cell</label>
            </div>
            <div className='col-md-4 field-group'>
              <input type="text" className={`form-control`} id={`_Address[0]._EmailAddress[0].EmailAddress`} name={`_Address[0]._EmailAddress[0].EmailAddress`} onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.EmailAddress} />
              <label for="email_id" className="form-label">Email</label>
            </div>
          </div>
          <h3 className='text-dark py-2'>Other Patient Data</h3>
          <div className='row mb-2'>
            <div className='col-md-4 field-group'>
              <select className="form-select bg-transparent" id="BusPartMaritalStatus" name="BusPartMaritalStatus" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.BusPartMaritalStatus}>
                <option selected>Marital  Status</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div className='col-md-4 field-group'>
              <input type="text" className={`form-control`} id="Language" name="Language" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.Language} />
              <label for="Language" className="form-label">Language</label>
            </div>
            <div className='col-md-4 field-group'>
              <input type="text" className={`form-control`} id="religion" name="religion" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.religion} />
              <label for="religion" className="form-label">Religion</label>
            </div>
            <div className='col-md-4 field-group'>
              <input type="text" className={`form-control`} id="cityOfBirth" name="cityOfBirth" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.cityOfBirth} />
              <label for="cityOfBirth" className="form-label">City of Birth</label>
            </div>
            <div className='col-md-4 field-group'>
              <input type="text" className={`form-control`} id="birthPlace" name="birthPlace" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.birthPlace} />
              <label for="birthPlace" className="form-label">Birthplace</label>
            </div>
          </div>
          <div className='formtopbtns'>
            <Button type="submit" className='btn-danger' variant="primary">Next</Button>
          </div>
        </Form>
      )}
    </Formik>
    
    </>
  )
}

export default Steps