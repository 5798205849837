// Input component for Edm.String type
const DefaultInput = ({ name }) => (
    <input
        className="form-control"
        name={name}
        id={name}
        placeholder={name}
    />
);

export default DefaultInput;
