import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CardImageViewItem from "../CardImageViewItem";

const PatientView = (props) => {
  return (
    <>
      <Modal
        onDoubleClick={(e) => e.stopPropagation()}
        className="add-patient-popup add-view-popup patient-view"
        show={props.show}
        onHide={props.handleClose}
        centered
      >
        <Modal.Header className="px-4">
          <Modal.Title className="text-light">Patient Details</Modal.Title>
          <Button
            variant="secondary"
            onClick={props.handleClose}
            className="bg-transparent shadow-none border-0 pe-0 pt-0"
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body className="p-4">
          <div className="patient-view-card">
            <CardImageViewItem details={props.details} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PatientView;