import { configureStore } from '@reduxjs/toolkit';
import MetaDataReducer from './slice/MetaDataSlice';
import layoutVariantReducer from './slice/layoutVariantSlice';
import sideMenuReducer from './slice/sideMenuSlice';
import menuReducer from './slice/MenuSlice';
import boardReducer from './slice/boardSlice';
import searchReducer from './slice/searchSlice';
import addPatientReducer from './slice/addPatientSlice';
import inPatientReducer from './slice/inPatientSlice';

export const store = configureStore({
    reducer: {
        board: boardReducer,
        metadata: MetaDataReducer,
        layoutvariant: layoutVariantReducer,
        sideMenu: sideMenuReducer,
        menu: menuReducer,
        search: searchReducer,
        addPatient: addPatientReducer,
        inPatient: inPatientReducer
    }
});
