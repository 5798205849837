import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";

const Steps = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setstartTime] = useState(new Date());
  const handleSubmit = (values) => {
    props.next(values)
  }
  return (
    <>
    <Formik initialValues={props.data} onSubmit={handleSubmit}>
      {(formprops) => (
        <Form>
            <h3 className='text-dark py-2'>Visit Data</h3>
            <div className='row mb-2'>
              <div className='col-md-4 field-group'>
                <select className="form-select" id="caseType" name="caseType" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.caseType}>
                  <option selected value="2 Outpatient">2 Outpatient</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                <label for="caseType" className="form-label">Case Type</label>
              </div>
              <div className='col-md-4 field-group'>
                <select className="form-select" id="visitType" name="visitType" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.visitType}>
                  <option selected value="Co Consultation">Co Consultation</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                <label for="visitType" className="form-label">Visit Type</label>
              </div>
            </div>
            <div className='row mb-2'>
              {/* <div className='col-md-4 field-group'>
                <DatePicker showIcon toggleCalendarOnIconClick selected={startDate} onChange={(date) => setStartDate(date)} />
                <input type="hidden" id="visitDate" onChange={(date) => setStartDate(date)} name="visitDate" value={formprops.values.visitDate = startDate} />
                <label for="visitDate" className="form-label">Date</label>
              </div>
              <div className='col-md-4 field-group'>
                <DatePicker selected={startTime} onChange={(date) => setstartTime(date)} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm aa" />
                <input type="hidden" id="visitTime" onChange={(date) => setStartDate(date)} name="visitTime" value={formprops.values.visitTime = startDate} />
                <label for="visitTime" className="form-label">Time</label>
              </div> */}
              <div className='col-md-4 field-group'>
                <select className="form-select" id="status" name="status" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.status}>
                  <option selected value="30 Check">30 Check</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                <label for="status" className="form-label">Status</label>
              </div>
            </div>
            <h3 className='text-dark py-2'>Organizational Assignment</h3>
            <div className='row mb-2'>
              <div className='col-md-4 field-group'>
                <select className="form-select" id="treatOu" name="treatOu" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.treatOu}>
                  <option selected value="2 Outpatient">2 Outpatient</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                <label for="treatOu" className="form-label">Treat.ou</label>
              </div>
              <div className='col-md-4 field-group'>
                <select className="form-select" id="deptOu" name="deptOu" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.deptOu}>
                  <option selected value="Co Consultation">Co Consultation</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                <label for="deptOu" className="form-label">Dept.ou</label>
              </div>
              <div className='col-md-4 field-group'>
                <input type="text" className={`form-control`} id="treatRoom" name="treatRoom" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.treatRoom} />
                <label for="treatRoom" className="form-label">Treat.room</label>
              </div>
              <div className='col-md-4 field-group'>
              <input type="text" className={`form-control`} id="bed" name="bed" onChange={formprops.handleChange} onBlur={formprops.handleBlur} value={formprops.values.bed} />
                <label for="bed" className="form-label">Bed</label>
              </div>
            </div>
            <div className='formtopbtns'>
              <Button className='btn-danger bg-primary text-light border-light me-3' variant="primary" onClick={() => props.prev(formprops.values)}>Previous</Button>
              <Button type="submit" className='btn-danger' variant="primary">Next</Button>
            </div>
        </Form>
      )}
    </Formik>
    
    </>
  )
}

export default Steps