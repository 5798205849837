import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../axios";

const initialState = {
    status: 'Idle',
    error: '',
    mainMenu: '',
}

export const getMainMenu = createAsyncThunk(
  'getMainMenu/menu',
  async () => {
    const response = await client.get(`/menu`)
    return response.data.value
  }
)

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
        .addCase(getMainMenu.pending, (state) => {
          state.status = 'loading'
          state.error = ''
        })
        .addCase(getMainMenu.fulfilled, (state, action) => {
          state.status = 'true'
          state.mainMenu = action.payload
          state.error = ''
        })
        .addCase(getMainMenu.rejected, (state, action) => {
          state.status = 'false'
          state.mainMenu = ''
          state.error = action.error.message
        })
    }
})

export default menuSlice.reducer