import React from 'react'
import FeatherIcon from 'feather-icons-react';
import { Col } from 'react-bootstrap'
import { Dropdown } from 'react-bootstrap';
import CalendarIcon from '../assets/images/calendar-icon.png'

const CardViewItem = () => {
  return (
    <Col md={3} className='mb-4'>
        <div className='card-view-item bg-light rounded position-relative p-0 overflow-hidden py-4'>
            <h5 className='text-dark px-4'>Jane Cooper</h5>
            <Dropdown>
                <Dropdown.Toggle className='d-flex bg-transparent border-0 text-start text-dark py-0 fs-3 lh-1'> 
                  <FeatherIcon icon="more-horizontal" className='ms-auto me-2' /> 
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">View</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Edit</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            <div className='card-date px-4 pb-2'>
                <span>03. 07. 1986, 26, Female</span>                        
            </div>
            <div className='card-days py-2 px-4 d-flex align-items-center mb-2' style={{background:"#D4DBEC"}}>
                <img src={CalendarIcon} alt='' className='me-2' />703 days
            </div>
            <div className='patient-count px-4'><strong className='text-blue-dark'>Patient</strong> 2002828 
            <strong className='ms-3 text-blue-dark'>Impatient</strong></div>
            <div className='risk-count px-4 py-1'><strong className='text-blue-dark'>Risk Factors </strong> 04 </div>
            <div className='alllergies-count px-4'><strong className='text-blue-dark'>Allergies</strong> 04 </div>
        </div>
    </Col>
  )
}

export default CardViewItem
