import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import xmlJs from 'xml-js';
import { client } from "../../axios";
const initialState = {
    status: 'Idle',
    error: '',
    metadatalist: '',
    modelservres: ''
}

export const getMetaData = createAsyncThunk(
  'getMetaData/metadata',
  async (modelServURL) => {
    const headers = {
      'Accept': 'application/xml',
      'Cache-Control': 'no-cache'
    }
    let response = await fetch(`${process.env.REACT_APP_BASE_URL}/metaData?url=${modelServURL}`,  { 
        auth: {
          'username': 'zaptech',
          'password': 'Zaptech@123'
        },
        headers: {headers} 
      })
    const xmltext = await response.text()
    const jsonData = xmlJs.xml2json(xmltext, { compact: true, spaces: 4 });
    return JSON.parse(jsonData)
}
)
export const getModelServRes = createAsyncThunk(
  'getModelServRes/metadata',
  async (modelServURL) => {
    const response = await client.get(`modelServRes?url=${modelServURL}`)
    return response.data.value
  }
)


export const MetaDataSlice = createSlice({
    name: 'metadata',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
        .addCase(getMetaData.pending, (state) => {
          state.status = 'loading'
          state.error = ''
        })
        .addCase(getMetaData.fulfilled, (state, action) => {
          state.status = 'true'
          state.metadatalist = action.payload
          state.error = ''
        })
        .addCase(getMetaData.rejected, (state, action) => {
          state.status = 'false'
          state.metadatalist = ''
          state.error = action.error.message
        })
        .addCase(getModelServRes.pending, (state) => {
          state.status = 'loading'
          state.error = ''
        })
        .addCase(getModelServRes.fulfilled, (state, action) => {
          state.status = 'true'
          state.metadatares = action.payload
          state.error = ''
        })
        .addCase(getModelServRes.rejected, (state, action) => {
          state.status = 'false'
          state.metadatares = ''
          state.error = action.error.message
        })
    }
})

export default MetaDataSlice.reducer