import React, { useEffect, useState } from "react";
import ListviewItem from './ListviewItem'
import { Dropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from "react-redux";
import { getLayoutCols, getLayoutColsData } from "../redux/slice/layoutVariantSlice";
import Loader from "./Loader";

const TableView = (props) => {
  // const [contacts, setContacts] = useState(CONTACTS);

  // const searchHandler = (event) => {
  //   let searcjQery = event.target.value.toLowerCase(),
  //     displayedContacts = CONTACTS.filter((el) => {
  //       let searchValue = el.name.toLowerCase();
  //       return searchValue.indexOf(searcjQery) !== -1;
  //     });
  //   setContacts(displayedContacts);
  // };
  
  const [tablelist, setTablelist] = useState(props.contacts);
  const [tableRowData, setTableRowData] = useState();
  const [cols, setcols] = useState([]);

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getLayoutCols())
    //dispatch(getLayoutColsData())
  }, [dispatch])

  const { status, error, layoutcols, layoutdata } = useSelector(state => state.layoutvariant)
  const { searchTableData } = useSelector(state => state.search);
  //console.log("layoutcols", layoutcols)
  //console.log("layoutdata", layoutdata)

  // const dataToDisplay = layoutdata && layoutdata?.filter(o1 => colsToDisplay?.some(o2 => Object.keys === o2.ColumnId));
  // console.log("dataToDisplay", dataToDisplay)

  const [dragOver, setDragOver] = useState([]);
    useEffect(()=>{
      // setTablelist(props.contacts)
      // const column = props.contacts && Object.keys(props.contacts[0]); 
      // setcols(column)
      const test = layoutcols && layoutcols?.filter((el) => el.LayVariantUuid === props.selectedBoard?._BoardVariants?.LayVariantUuid)
      const colsToDisplay = test && test[0]?._LytVarAssgn?.filter(o1 => props.metaTableCols?.some(o2 => o1.ColumnId === o2._attributes?.Name));
      const tableColumnData = colsToDisplay && colsToDisplay.map((el) => el.ColumnId);
      setcols(tableColumnData);
      const tableRows = (searchTableData && searchTableData.length >0) ? searchTableData : layoutdata
      const tableRowData = tableRows && tableRows.map(obj =>
        Object.fromEntries(
          Object.entries(obj).filter(([key]) => tableColumnData.includes(key))
        )
      );
      setTableRowData(tableRowData)
      setTablelist(tableRowData);
    },[layoutcols, layoutdata, searchTableData, props.selectedBoard, props.metaTableCols])

    const { searchText } = useSelector(state => state.search)
    const ApplyFilter = () => {
      let updatedList = tablelist
        if(searchText && tablelist) {
            updatedList = updatedList.filter((item) => Object.keys(item).some(key => item[key].toString().search(searchText.toLowerCase().trim()) !== -1))
        }
        else {
          updatedList = []
        }
        if(tablelist && updatedList.length > 0){
          setTablelist(updatedList);
        }
        else{
          setTablelist(tableRowData);
        }
        //console.log("updatedList", updatedList, tablelist)
  };
  useEffect(()=>{
    ApplyFilter()  
  },[searchText])
  

  const handleDragStart = e => {
    // let element = e.currentTarget;
    // element.classList.add("dragged");
    const { id } = e.target;
    const idx = cols.indexOf(id);
    e.dataTransfer.setData("colIdx", idx);
  };

  const handleDragOver = e => {
    e.preventDefault();
  }
  const onDragEnd = e => {
    // e.target.classList.remove("dragged");
  };
  const onDragLeave = e => {
    //e.target.classList.remove("dragged");
  }
  const handleDragEnter = e => {
    const { id } = e.target;
    // let element = e.currentTarget;
    // element.classList.add("dragged");
    setDragOver(id);     
  };

  const handleOnDrop = e => {
    const { id } = e.target;
    const droppedColIdx = cols.indexOf(id);
    const draggedColIdx = e.dataTransfer.getData("colIdx");
    const tempCols = [...cols];
    // e.target.classList.remove("dragged");
    
    tempCols[draggedColIdx] = cols[droppedColIdx];
    tempCols[droppedColIdx] = cols[draggedColIdx];
    setcols(tempCols);
    setDragOver("");
    props.viewCreateHandler()    
  };

  if(status === 'loading' || props.selectedBoard === undefined || cols === undefined || props.metaTableCols.length === 0) {
    return <div>Loading...</div>
  }
  
  return (
    <div className='right-content-box'>
      {/* <input type="text" className="search" onChange={searchHandler} /> */}
      <div className='control-bar'></div>
      <div className="list-view">
        <table className="table">
            <thead>              
                <tr key="headings">
                  {
                  cols && cols.map((item)=>{
                    return <th
                            id={item}
                            key={item}
                            draggable
                            onDragStart={handleDragStart}
                            onDragOver={handleDragOver}
                            onDragEnd={onDragEnd}
                            onDrop={handleOnDrop}       
                            onDragLeave={onDragLeave}                     
                            onDragEnter={handleDragEnter} 
                            dragOver={item === dragOver} 
                            className={`${item === dragOver && "drag-col"}`}
                      >
                      {
                        item
                      }
                    </th>
                  })
                  }
                </tr>
            </thead>
            <tbody>
              {tablelist && tablelist.map((row, index) => (
              <tr key={index} id={index}>
                {Object.entries(row).map(([k, v], idx) => (
                  <td className={`k ${cols[idx] === dragOver && "drag-col"}`} key={v} dragOver={cols[idx] === dragOver} >
                     {k === "Action" ? 
                     (
                      <Dropdown>
                          <Dropdown.Toggle className='d-flex bg-transparent border-0 text-start text-dark py-0 fs-3 lh-1'> ... </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">View</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Edit</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                     )
                     : row[cols[idx]]} 

                    
                  </td>
                ))}
              </tr>
            ))}</tbody>
          </table>
        </div>
        
    </div>
  )
}

export default TableView
