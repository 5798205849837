import React from 'react'
import FeatherIcon from 'feather-icons-react';
import ControlIcon2 from '../assets/images/control-icon2.png';
import ControlIcon3 from '../assets/images/control-icon3.png';
import ControlIcon4 from '../assets/images/control-icon4.png';
import ControlIcon5 from '../assets/images/control-icon5.png';
import ControlIcon6 from '../assets/images/control-icon6.png';
import ControlIcon7 from '../assets/images/control-icon7.png';

const controls = (props) => {
  const isMobile = window.innerWidth < 992;
  return (
    <div className='controls-row d-flex align-items-center justify-content-between'>
      <div className='controls-left d-flex align-items-center'>
            <span className='d-flex align-items-center'><FeatherIcon icon="repeat" /></span>
            <span className='d-flex align-items-center'><img src={ControlIcon2} alt='' /></span>
            <span className='d-flex align-items-center'><img src={ControlIcon3} alt='' /></span>
      </div>
      {props.tableview === 'B' || props.tableview === 'E' ? (
        <div className='controls-center d-flex align-items-center'>
        <label className='pe-2'>Card Size</label>
        {/* <span className='card-size-icon card-size1 d-flex align-items-center'></span> */}
        {/* <span className='card-size-icon card-size2 d-flex align-items-center' onClick={() => props.handleChange("B")}></span> */}
        <span className={`card-size-icon card-size2 d-flex align-items-center ${props.tableview ==='B' ? 'active-control' : ''}`} onClick={() => props.handleChange("B")}></span>
        <span className={`card-size-icon card-size3 d-flex align-items-center ${props.tableview ==='E' ? 'active-control' : ''}`} onClick={() => props.handleChange("E")}></span>
        {/* <span className='card-size-icon card-size4 d-flex align-items-center'></span>
        <span className='card-size-icon card-size5 d-flex align-items-center'></span> */}
      </div>
      ): null}
      

      <div className='controls-right d-flex align-items-center'>
       <label className='pe-2'>View</label>
       {/* <span className='d-flex align-items-center'  onClick={() => props.handleChange("A")}><img src={ControlIcon5} alt='' /></span> */}
       
       {!isMobile ? <span className={`d-flex align-items-center ${props.tableview ==='A' ? 'active-control' : ''}`}  onClick={() => props.handleChange("A")}><img src={ControlIcon5} alt='' /></span> : ''}
       <span className={`d-flex align-items-center ${props.tableview ==='B' || props.tableview ==='E' ? 'active-control' : ''}`} onClick={() => props.handleChange("B")}><img src={ControlIcon4} alt='' /></span>
       <span className={`d-flex align-items-center ${props.tableview ==='C' ? 'active-control' : ''}`} onClick={() => props.handleChange("C")}><img src={ControlIcon6} alt='' /></span>
       {!isMobile ?<span className={`d-flex align-items-center ${props.tableview ==='D' ? 'active-control' : ''}`} onClick={() => props.handleChange("D")}><img src={ControlIcon7} alt='' /></span> : ''}
      </div>
    </div>
  )
}

export default controls
