import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../axios";

const initialState = {
    status: 'Idle',
    error: '',
    selectionVarData: '',
    searchTableData:''
}

export const selectionVar = createAsyncThunk(
    'selectionVar/search',
    async () => {
      const response = await client.get(`/selectionvar`)
      return response.data.value
    }
  )

export const getSearchtableData = createAsyncThunk(
  'getSearchtableData/metadata',
  async (data) => {
    const response = await client.get(`/SearchtableData?url=${data.modelServURL}&modelURLres=${data.resurl}&odataFilter=${data.odataFilter}`)
    return response.data.value
  }
) 

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
      searchHandler: (state, action) => {
        state.searchText = action.payload
      }
    },
    extraReducers: (builder) => {
        builder
        .addCase(selectionVar.pending, (state) => {
          state.status = 'loading'
          state.error = ''
        })
        .addCase(selectionVar.fulfilled, (state, action) => {
          state.status = 'true'
          state.selectionVarData = action.payload
          state.error = ''
        })
        .addCase(selectionVar.rejected, (state, action) => {
          state.status = 'false'
          state.selectionVarData = ''
          state.error = action.error.message
        })
        .addCase(getSearchtableData.pending, (state) => {
          state.status = 'loading'
          state.error = ''
        })
        .addCase(getSearchtableData.fulfilled, (state, action) => {
          state.status = 'true'
          state.searchTableData = action.payload
          state.error = ''
        })
        .addCase(getSearchtableData.rejected, (state, action) => {
          state.status = 'false'
          state.searchTableData = ''
          state.error = action.error.message
        })
    }
})

export const { searchHandler } = searchSlice.actions
export default searchSlice.reducer