import React from 'react';
// Input component for Edm.String type
const BooleanInput = ({ name }) => (
    <div className='row'>
      <div className='col-md-6'>
      <div class="form-check">
        <input type="radio" name={name} value="true" /> 
        <label class="form-check-label" for="flexCheckDefault">
        True
        </label>
      </div>
      </div>
      <div className='col-md-6'>
      <div class="form-check">
      <input type="radio" name={name} value="false" /> 
        <label class="form-check-label" for="flexCheckDefault">
        False
        </label>
      </div>
      </div>
    </div>
  );

export default BooleanInput;
