import React from 'react';
import { Field } from 'formik';

const DateInput = ({ name }) => (    
  <div className='row'>
    <div className='col-md-6'>
      <Field type="date" id={name} name={name} placeholder={name} className="form-control" />
      {/* <input
        type="date"
        className="form-control"
        name={name}
        id={name}
        placeholder={name}
      /> */}
    </div>
    <div className='col-md-6'>
      <Field type="date" id={`${name}_2`} name={`${name}_2`} placeholder={name} className="form-control" />
      {/* <input
        type="date"
        className="form-control"
        name={name}
        id={name}
        placeholder={name}
      /> */}
    </div>
  </div>
);

export default DateInput;
