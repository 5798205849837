// Input component for Edm.String type
import { Field } from 'formik';
const StringInput = ({ name, maxLength }) => (
    <Field id={name} name={name} placeholder={name} className="form-control" />
    // <input
    //     className="form-control"
    //     name={name}
    //     id={name}
    //     placeholder={name}
    //     maxLength={maxLength}
    // />
);

export default StringInput;
