import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../axios";

const initialState = {
    status: 'Idle',
    error: '',
    patientData: '',
}

export const addPatientData = createAsyncThunk(
  'addPatientData/addpatient',
  async (data) => {
    const datan = {
      "@odata.metadataEtag" : "W/\"20240509065208\"",
      "CorrespondenceLanguage" : "EN",
      "FormOfAddress" : "0002",
      "GenderCodeName" : "2",
      "Language" : "EN",
      "BirthDate" : "1997-05-05",
      "BusPartMaritalStatus" : "2",
      "BusPartNationality" : "AE",
      "_Address" : [
        {
          "ValidityStartDate" : "2024-05-05T00:00:00Z",
          "ValidityEndDate" : "9999-12-31T23:59:59Z",
          "AddressTimeZone" : "UTC+4",
          "CityName" : "wert",
          "Country" : "AE",
          "District" : "",
          "Building" : "",
          "RoomNumber" : "",
          "Floor" : "",
          "Language" : "",
          "POBox" : "",
          "PostalCode" : "567",
          "Region" : "",
          "StreetName" : "ghj",
          "_EmailAddress" : [
            {
              "EmailAddress" :"abc@mail.com"
            }
          ],
          "_FaxNumber" : [
            {
              "FaxCountry" : "AE",
              "FaxNumber" : "989898",
              "FaxNumberExtension" : ""
            }
          ],
          "_MobilePhoneNumber" : [
            {
              "DestinationLocationCountry" : "AE",
              "PhoneNumber" : "9345549334",
              "PhoneNumberExtension" : "",
              "InternationalPhoneNumber" : "+9719345549334"
            }
          ],
          "_PhoneNumber" : [
            {
              "DestinationLocationCountry" : "AE",
              "PhoneNumber" : "2345549334",
              "PhoneNumberExtension" : "",
              "InternationalPhoneNumber" : "+9712345549334"
            }
          ]
        }
      ],
      "_BuPaIdentif" : [
        {
          "BPIdentificationType" : "FS0001",
          "BPIdentificationNumber" : "AE12345",
          "BPIdnNmbrIssuingInstitute" : "",
          "BPIdentificationEntryDate" : "2024-04-01",
          "Country" : "AE",
          "Region" : "",
          "ValidityStartDate" : "2024-04-01",
          "ValidityEndDate" : "2024-12-31"
        }
      ],
      "_Customer" : {
        "Customer" : "1",
        "AlternativePayerAccount" : "",
        "AlternativePayeeIsAllowed" : false,
        "PaymentReason" : ""
      },
      "_Patient" : {
        "Inactive" : false,
        "_Name" : [
          {
            "PatientId" : "0000000000",
            "NameUse" : "2",
            "Spras" : "EN",
            "DispName" : false,
            "FullName" : "",
            "LastName" : "DummyLSTNAME",
            "MiddleName" : "",
            "FirstName" : "FSTNAME",
            "NamePrefix" : "",
            "NameSuffix" : "",
            "NamePeriod" : null
          }
        ]
      }
    }
    const response = await client.post(`/addPatientData`, data)
    return response.data.value
  }
)

export const addPatientSlice = createSlice({
    name: 'addpatient',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
        .addCase(addPatientData.pending, (state) => {
          state.status = 'loading'
          state.error = ''
        })
        .addCase(addPatientData.fulfilled, (state, action) => {
          state.status = 'true'
          state.patientData = action.payload
          state.error = ''
        })
        .addCase(addPatientData.rejected, (state, action) => {
          state.status = 'false'
          state.patientData = ''
          state.error = action.error.message
        })
    }
})

export default addPatientSlice.reducer