import React from 'react';
// Input component for Edm.TimeOfDay type
const TimeInput = ({ name }) => (
    <input
      type="time"
      className="form-control"
      name={name}
      id={name}
      placeholder={name}
    />
  );

export default TimeInput;
