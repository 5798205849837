import React, { useState, useEffect } from "react";
import Logo from '../assets/images/logo.png'
import SideMenu from './SideMenu'
import SideFilter from './SideFilter'
import SideCollapseArrow from '../assets/images/left-collapse-arrow.png'
import { Link } from 'react-router-dom'
import xmlJs from 'xml-js';
import { client } from "../axios";
import { useDispatch, useSelector } from "react-redux";
import { getActionVariant } from "../redux/slice/sideMenuSlice";


const SidePanelDesktop = (props) => {
  
  const [switchToggled, setswitchToggled] = useState(false)

  const ToggleSwitch = () => {
    switchToggled ? setswitchToggled(false) : setswitchToggled(true);
  }
  const [xmlData, setXmlData] = useState();
  
  // const dispatch = useDispatch()
  // useEffect(() => {
  //   dispatch(getActionVariant())
  // }, [dispatch])

  // const { status, error, actionVariants } = useSelector(state => state.sideMenu) 
  
  // const actionToDisplay = actionVariants && actionVariants?.filter(o1 => props.metaActions?.some(o2 => o1._ActVarAssgnDescr[0].ActionId === o2._attributes?.Name));

  return (
    <div className={switchToggled ? "sidebar collapsed-sidebar" : "sidebar"} style={{width:'257px', minWidth:'257px', background:"#EFEFEF", position:"relative"}}>
      <div className='sidebar-collapse-arrow' onClick={ToggleSwitch}><img src={SideCollapseArrow} alt='' /> </div>
      
       <Link to="/" onClick={props.handleshowcontrols}> <div className='logo bg-light'> <img src={Logo} alt='' /></div>
        <div className='logo-collapsed'><span>L</span></div></Link>
      
      <SideMenu actionToDisplay={props.actionToDisplay} />
      <SideFilter filters={props.filterToDisplay} />
    </div>
  )
}

export default SidePanelDesktop
