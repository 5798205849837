import React from "react";
import "./assets/scss/style.scss";
import Layout from "./component/Layout";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <>
      <Toaster position="top-center" />
      <Layout />;
    </>
  );
}

export default App;