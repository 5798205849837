import React, { useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import DatePicker from "react-datepicker";
import { Dropdown } from "react-bootstrap";
import AddViewPopup from "./Modals/AddViewPopup";
import { useDispatch, useSelector } from "react-redux";
import {
  getSearchtableData,
  searchHandler,
  selectionVar,
} from "../redux/slice/searchSlice";
import DynamicInput from "./Search/DynamicInput";
import { Formik, Field, Form } from "formik";
import dayjs from "dayjs";

const Search = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setstartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());

  const [showSearchDropdown, setshowSearchDropdown] = useState(false);
  const showsearchHandler = () => {
    setshowSearchDropdown(!showSearchDropdown);
  };

  const dispatch = useDispatch();
  const [searchState, setSearchState] = useState("");
  const searchHandlerAction = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    console.log("value---", value);
    dispatch(searchHandler(value));
  };
  const { metadatalist } = useSelector((state) => state.metadata);

  const [formFieldToDisplay, setFormFieldToDisplay] = useState("");

  useEffect(() => {
    console.log({ metadatalist });
    dispatch(selectionVar()).then((res) => {
      const test =
        res &&
        res.payload?.filter(
          (el) =>
            el.SelVariantUuid ===
            props.selectedBoard?._BoardVariants?.SelVariantUuid
        );
      console.log("test", test);
      const filterformFieldToDisplay =
        metadatalist &&
        metadatalist?.["edmx:Edmx"]?.[
          "edmx:DataServices"
        ]?.Schema?.EntityType?.Property.filter((o1) =>
          test[0]?._SelVarAssgn.some(
            (o2) =>
              o1._attributes?.Name?.toLowerCase() === o2.FieldId?.toLowerCase()
          )
        );
      setFormFieldToDisplay(filterformFieldToDisplay);
      console.log("filterformFieldToDisplay", filterformFieldToDisplay);
    });
  }, [dispatch]);

  useEffect(() => {}, [props.viewcreate]);

  const isCurrentPageDesignFloorEdit =
    window.location.pathname.includes("designflooredit");

  // Function to convert a value to OData format based on its data type
  const formatValueForOData = (fieldName, value, type, formValues) => {
    let toField;
    let toFieldValue;
    switch (type) {
      case "Edm.Boolean":
        return `${fieldName} eq '${value}'`;
      case "Edm.String":
        return `${fieldName} eq '${value}'`;
      case "Edm.Byte":
        return `${fieldName} eq '${value}'`;
      case "Edm.Int16":
        toField = `${fieldName}`;
        toFieldValue = `${dayjs(formValues[`${fieldName}_2`]).format(
          "YYYY-MM-DD"
        )}`;
        return `${fieldName} ge ${dayjs(value).format(
          "YYYY-MM-DD"
        )} and ${toField} le ${toFieldValue}`;
      case "Edm.Int32":
        toField = `${fieldName}`;
        toFieldValue = `${dayjs(formValues[`${fieldName}_2`]).format(
          "YYYY-MM-DD"
        )}`;
        return `${fieldName} ge ${dayjs(value).format(
          "YYYY-MM-DD"
        )} and ${toField} le ${toFieldValue}`;
      case "Edm.Int64":
        toField = `${fieldName}`;
        toFieldValue = `${dayjs(formValues[`${fieldName}_2`]).format(
          "YYYY-MM-DD"
        )}`;
        return `${fieldName} ge ${dayjs(value).format(
          "YYYY-MM-DD"
        )} and ${toField} le ${toFieldValue}`;
      case "Edm.Date":
        toField = `${fieldName}`;
        toFieldValue = `${dayjs(formValues[`${fieldName}_2`]).format(
          "YYYY-MM-DD"
        )}`;
        return `${fieldName} ge ${dayjs(value).format(
          "YYYY-MM-DD"
        )} and ${toField} le ${toFieldValue}`;
      case "Edm.TimeOfDay":
        toField = `${fieldName}`;
        toFieldValue = `${dayjs(formValues[`${fieldName}_2`]).format(
          "YYYY-MM-DD"
        )}`;
        return `${fieldName} ge ${dayjs(value).format(
          "YYYY-MM-DD"
        )} and ${toField} le ${toFieldValue}`;
      case "Edm.DateTimeOffset":
        toField = `${fieldName}`;
        toFieldValue = `${dayjs(formValues[`${fieldName}_2`]).format(
          "YYYY-MM-DD"
        )}`;
        return `${fieldName} ge ${dayjs(value).format(
          "YYYY-MM-DD"
        )} and ${toField} le ${toFieldValue}`;
      case "Edm.Decimal":
        toField = `${fieldName}`;
        toFieldValue = `${dayjs(formValues[`${fieldName}_2`]).format(
          "YYYY-MM-DD"
        )}`;
        return `${fieldName} ge ${dayjs(value).format(
          "YYYY-MM-DD"
        )} and ${toField} le ${toFieldValue}`;
      default:
        return `'${value}'`;
    }
  };

  // Function to construct the OData filter string for a single form field
  const constructFilterString = (name, value, type, formValues) => {
    return formatValueForOData(name, value, type, formValues);
  };

  // Function to construct the complete OData filter string for all form fields
  const constructODataFilter = (formData, formValues) => {
    const filters = [];
    formData.forEach(({ name, value, type }) => {
      if (value) {
        filters.push(constructFilterString(name, value, type, formValues));
      }
    });
    return filters.length ? `$filter=${filters.join(" and ")}` : "";
  };

  const test =
    formFieldToDisplay &&
    formFieldToDisplay.map((item) => ({ [item._attributes.Name]: "" }));
  let obj = Object.assign({}, ...test);

  const handleSearch = (values) => {
    showsearchHandler();

    console.log({ values });
    const mappedSearchFieldData = Object.keys(values)
      .map((val, index) => {
        //console.log({valuesVal: values[val], val})
        if (values[val]) {
          // || values[`${val}_2`]
          const ffd = formFieldToDisplay.find((item) => {
            return item._attributes.Name === val; // ||  `${item._attributes.Name}_2` === val
          });
          if (ffd) {
            return {
              value: values[val],
              name: ffd._attributes.Name,
              type: ffd._attributes.Type,
            };
          }
          return undefined;
        }
        return undefined;
      })
      .filter((item) => item !== undefined);
    const odataFilter = constructODataFilter(mappedSearchFieldData, values);
    console.log({ odataFilter });
    const data = {
      modelServURL: props.modelServURL,
      resurl: props.searchResUrl,
      odataFilter: odataFilter,
    };
    console.log("data---", data);
    dispatch(getSearchtableData(data));
  };

  return (
    <div className="serch-content">
      <div className="search-left">
        <form className="bg-light p-2 rounded d-flex">
          <button type="submit" className="border-0 bg-transparent">
            <FeatherIcon icon="search" />
          </button>
          <input
            type="text"
            placeholder="Search Patient Name"
            name="searchtext"
            className="border-0 bg-transparent"
            value={searchState.value}
            id="searchtext"
            onChange={(e) => searchHandlerAction(e)}
          />
          <FeatherIcon
            icon="sliders"
            className="ms-auto me-2 cursor-pointer"
            onClick={showsearchHandler}
          />
        </form>
        <label className="text-light pt-1">
          2nd Floor-Zone C-IP On 14-12-2022 13:06 with 24 Patients
        </label>
        <CSSTransition
          in={showSearchDropdown}
          timeout={100}
          classNames="slidedown"
          unmountOnExit
        >
          <div className="search-dropdown">
            {/* {formFieldToDisplay && formFieldToDisplay?.map((item) => (
              item?._attributes.Type === 'Edm.String' ? (
                <div className='row mb-2'>
                  <div className='col-md-3 d-flex align-items-center'>
                    <label for="Case ID" className="form-label">{item?._attributes.Name}</label>
                  </div>
                  <div className='col-md-9'>
                    <input className="form-control" name={item?._attributes.Name} id={item?._attributes.Name} placeholder={item?._attributes.Name} maxLength={item?._attributes.MaxLength}></input>
                  </div>
                </div>
              ) : null              
            ))} */}
            <Formik
              initialValues={obj}
              onSubmit={async (values) => {
                await new Promise((r) => setTimeout(r, 500));
                handleSearch(values);
                console.log("value====s", values);
              }}
            >
              <Form>
                {formFieldToDisplay &&
                  formFieldToDisplay.map((item, index) => (
                    <DynamicInput key={index} item={item} />
                  ))}
                <div className="row mb-2 text-center pt-3">
                  <div className="col-md-12">
                    <button type="submit" className="btn btn-danger">
                      Search
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
            {/* <div className='row mb-2'>
              <div className='col-md-3 d-flex align-items-center'>
                <label for="date" className="form-label">Date</label>
              </div>
              <div className='col-md-9'>
                <div className='row'>
                  <div className='col-md-6'><DatePicker showIcon toggleCalendarOnIconClick selected={startDate} onChange={(date) => setStartDate(date)} /></div>
                  <div className='col-md-6'><DatePicker showIcon toggleCalendarOnIconClick selected={endDate} onChange={(date) => setEndDate(date)} /></div>
                </div>
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-3 d-flex align-items-center'>
                <label for="time" className="form-label">Time</label>
              </div>
              <div className='col-md-9'>
                <div className='row'>
                  <div className='col-md-6'>
                    <DatePicker selected={startTime} onChange={(date) => setstartTime(date)} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm aa" />
                  </div>
                  <div className='col-md-6'>
                    <DatePicker selected={endTime} onChange={(date) => setEndTime(date)} showTimeSelect showTimeSelectOnly timeIntervals={15} timeCaption="Time" dateFormat="h:mm aa" />
                  </div>
                </div>
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-3 d-flex align-items-center'>
                <label for="patientId" className="form-label">Patient ID</label>
              </div>
              <div className='col-md-9'>
                <input className="form-control" id="patientId" placeholder="Patient ID"></input>
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-3 d-flex align-items-center'>
                <label for="Case ID" className="form-label">Case ID</label>
              </div>
              <div className='col-md-9'>
                <input className="form-control" id="Case ID" placeholder="Case ID"></input>
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-3 d-flex align-items-center'>
                <label for="tel" className="form-label">Tel. No</label>
              </div>
              <div className='col-md-9'>
                <input className="form-control" id="tel" placeholder="Tel. No"></input>
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-3 d-flex align-items-center'>
                <label for="Department" className="form-label">Department</label>
              </div>
              <div className='col-md-9'>
                <select className="form-select" aria-label="Department">
                  <option>Department</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-3 d-flex align-items-center'>
                <label for="Treatment Unit" className="form-label">Treatment Unit</label>
              </div>
              <div className='col-md-9'>
                <select className="form-select" aria-label="Default select example">
                  <option>Treatment Unit</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col-md-3 d-flex align-items-center'>
                <label for="team" className="form-label">Team ID</label>
              </div> 
              <div className='col-md-9'>
                <input className="form-control" id="team" placeholder="Team ID"></input>
              </div>
            </div> */}
          </div>
        </CSSTransition>
      </div>
      {props.floorSide && props.tableview == "D" ? (
        <>
          <button
            type="button"
            className="btn btn-danger mx-2 search-save-btn gap-1 pe-4 d-flex align-items-center"
          >
            <FeatherIcon icon="save" className="mx-2" /> Save
          </button>
        </>
      ) : (
        ""
      )}
      {props.hidecontrols ? (
        <div className="search-right">
          <button
            disabled={props.disable}
            type="button"
            onClick={() => {
              console.log("clicking");
              props.setToggleSave((prev) => !prev);
            }}
            className="btn btn-danger mx-2 search-save-btn gap-1 pe-4 d-flex align-items-center"
          >
            <FeatherIcon icon="save" className="mx-2" /> Save
          </button>
        </div>
      ) : (
        ""
      )}
      {props.tableview == "A" ||
      (props.tableview == "D" && isCurrentPageDesignFloorEdit) ? (
        <div className="search-right">
          <select className="form-select btn btn-outline-light text-light ps-3 pe-5 me-2">
            <option>View 01</option>
            <option value="1">View 02</option>btn btn-danger ms-2
            search-save-btn pe-4 d-flex align-items-center disabled
            <option value="2">View 03</option>
            <option value="3">View 04</option>
          </select>

          <button
            type="button"
            disabled={props.disable}
            onClick={() => {
              // setShow(true);
              console.log("clicking");
              props.setToggleSave((prev) => !prev);
            }}
            // disabled={props.viewcreate ? false : true}
            className="btn btn-danger ms-2 search-save-btn pe-4 d-flex gap-1 align-items-center"
          >
            <FeatherIcon icon="save" className="mx-2" />
            Save
          </button>

          <Dropdown>
            <Dropdown.Toggle className="d-flex gap-1 bg-transparent border-0 text-start text-dark py-0 fs-3 lh-1">
              <FeatherIcon
                icon="more-vertical"
                width="48px"
                className="text-light display-1 ms-2"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu className="mt-3 me-2">
              <Dropdown.Item href="#/action-1">Rename</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <AddViewPopup
            show={show}
            handleClose={handleClose}
            handleShow={handleShow}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Search;