import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const AddViewPopup = (props) => {
  return (
    <>
     <Modal className='add-patient-popup add-view-popup' show={props.show} onHide={props.handleClose} centered>
        <Modal.Header>
          <Modal.Title className='text-light'>Create View</Modal.Title>          
          <Button variant="secondary" onClick={props.handleClose} className='bg-transparent shadow-none border-0 pe-0 pt-0'>
            X
          </Button>
        </Modal.Header>
          <Modal.Body className='p-4'>
          <div className='col-md-12 field-group m-0'>
                <input className="form-control mb-3 border" id="View Name" placeholder='Enter View Name'></input>
                <Button className='btn-danger ms-auto me-3' variant="primary" onClick={props.handleClose}>
            Save
          </Button>
            </div>
          </Modal.Body>
         
      </Modal> 
    </>
  )
}

export default AddViewPopup
