import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../axios";

const initialState = {
    status: 'Idle',
    error: '',
    boardData: '',
}

export const getBoardData = createAsyncThunk(
  'getLayoutCols/board',
  async () => {
    const response = await client.get(`/boardData`)
    return response.data.value
  }
)

export const boardSlice = createSlice({
    name: 'board',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
        .addCase(getBoardData.pending, (state) => {
          state.status = 'loading'
          state.error = ''
        })
        .addCase(getBoardData.fulfilled, (state, action) => {
          state.status = 'true'
          state.boardData = action.payload
          state.error = ''
        })
        .addCase(getBoardData.rejected, (state, action) => {
          state.status = 'false'
          state.boardData = ''
          state.error = action.error.message
        })
    }
})

export default boardSlice.reducer