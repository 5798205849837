import React, { useState, useEffect } from "react";
import Logo from '../assets/images/logo.png'
import SideMenu from './SideMenu'
import SideFilter from './SideFilter'
import { CSSTransition } from 'react-transition-group';
import SideCollapseArrow from '../assets/images/left-collapse-arrow.png'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getActionVariant } from "../redux/slice/sideMenuSlice";

const SidePanel = (props) => {
  const [showmobilemenu, setshowmobilemenu] = useState(true)

  const [switchToggled, setswitchToggled] = useState(false)

  const ToggleSwitch = () => {
    switchToggled ? setswitchToggled(false) : setswitchToggled(true);
  }

  // const [xmlData, setXmlData] = useState();

  // useEffect(() => {
  //     fetch('sap/opu/odata4/SMY/DUMMYWORKLIST/srvd/SMY/DUMMYWRKLST/0001/$metadata?sap-client=100',
  //     { 
  //       auth: {
  //         'username': 'zaptech',
  //         'password': 'Zaptech@123'
  //       }
  //     },) // Replace with the URL or path to your XML data
  //     .then((response) => response.text())
  //     .then((xmlText) => {
  //       const jsonData = xmlJs.xml2json(xmlText, { compact: true, spaces: 4 });
  //       setXmlData(JSON.parse(jsonData));
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching XML data:', error);
  //     });
  // }, [])

  // const actions = xmlData?.['edmx:Edmx']?.['edmx:DataServices']?.Schema?.Action || [];
  // const filters = xmlData?.['edmx:Edmx']?.['edmx:DataServices']?.Schema?.Function || [];
  
  // const dispatch = useDispatch()
  // useEffect(() => {
  //   dispatch(getActionVariant())
  // }, [dispatch])

  // const { status, error, actionVariants } = useSelector(state => state.sideMenu) 
  
  // const actionToDisplay = actionVariants && actionVariants?.filter(o1 => props.metaActions?.some(o2 => o1._ActVarAssgnDescr[0].ActionId === o2._attributes?.Name));
  
  const filters = props.metaActions?.['edmx:Edmx']?.['edmx:DataServices']?.Schema?.Function || [];

  return (
    <div className={switchToggled ? "sidebar collapsed-sidebar" : "sidebar"} style={{width:'257px', minWidth:'257px', background:"#EFEFEF", position:"relative"}}>
      <div className='sidebar-collapse-arrow' onClick={ToggleSwitch}><img src={SideCollapseArrow} alt='' /> </div>

      <Link to="/" onClick={props.handleshowview}> 
        <div className='logo bg-light'><img src={Logo} alt='' /></div>
        <div className='logo-collapsed'><span>L</span></div>  
      </Link>
      
      <CSSTransition in={props.sideMenu} timeout={500} classNames="slideup" unmountOnExit>
        <>
        <SideMenu actionToDisplay={props.actionToDisplay} /> <div className='side-overlay' onClick={props.handleSideMenu}></div>
        </>
      </CSSTransition>
       
      <CSSTransition in={props.sideFilter} timeout={500} classNames="slideup" unmountOnExit>
        <>
          <SideFilter filters={props.filterToDisplay} /> <div className='side-overlay' onClick={props.handleSideFilter}></div>
        </>
        </CSSTransition>
      </div>   
  )
}

export default SidePanel
