import React from 'react'
import { useState} from "react";
import UserIcon from '../assets/images/user-icon.png'
import MedicalCross from '../assets/images/medical-cross.png';
import PatientView from './Modals/PatientView';

const BedBoxItem = ({bed, onDragLeave, onDragEnter, onDragEnd, onDragOver, onDrop, id, PatientList, removePatient }) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);   
 

  return (  
      <div className='bed-box' 
        onDragLeave={e => onDragLeave(e)}
        onDragEnter={e => onDragEnter(e)}
        onDragEnd={e => onDragEnd(e)}
        onDragOver={e => onDragOver(e)}
        onDrop={e => onDrop(e, true)}
        id={id}
      >
        
        { bed === 'fill' ? (
            
            PatientList.map((item) => {
                return id === parseInt(item.bedId) ? 
                (
                    <div key={item.id} className={`bed position-relative align-items-center ${bed === 'fill' ?  item.gender==='female' ? 'women-bed' : 'men-bed' : null}`}>
                        <div className='bed-close-icon position-absolute end-0 top-0 bg-dark rounded p-1 text-white' role="button" onClick={() => removePatient(item.id, id)}>X</div>
                        <div className='lefticon '>
                            <img src={UserIcon} alt='' className='user-icon' />
                        </div>     
                        <div className='bed-patient-details d-flex flex-column text-dark'>
                            <h6 className='m-0'>{item.name}</h6>
                                <p className='d-flex flex-column lh-1'>
                                    <span className='age'>{item.age}/<span className='sex'>{item.gender}</span></span>
                                    
                                    <button type="button" onClick={()=> setShow(true)} className='patient-view-details pt-3'> View Details</button>
                                </p>         
                        </div>
                    </div>
                )
                : null;
            })
           
            )
            :
            (
                
            <div className={`bed position-relative align-items-center ${bed === 'fill' ? "" : null}`}>
                <div className='lefticon '>
                    <img src={UserIcon} alt='' className='user-icon' />
                </div>     
                <div className='bed-patient-details d-flex flex-column text-dark'>
                    <img src={MedicalCross} alt='' className='medical-cross-icon' />
                </div>
            </div> 
            
            
            ) 
        }


    <PatientView show={show} handleClose={handleClose} handleShow={handleShow} />
    </div>    
  )
}

export default BedBoxItem
