import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const PatientInformationForm = (props) => {
    console.log("popupInfo", props.popupInfo)
  return (
    <>
     <Modal className='add-patient-popup add-view-popup' show={props.show} onHide={props.handleClose} centered>
        <Modal.Header>
          <Modal.Title className='text-light'>Patient Information</Modal.Title>          
          <Button variant="secondary" onClick={props.handleClose} className='bg-transparent shadow-none border-0 pe-0 pt-0'>
            X
          </Button>
        </Modal.Header>
          <Modal.Body className='p-4'>
          <div className='col-md-12 field-group m-0'>
                <input className="form-control mb-3 border" type='text' id="Name" value={props.popupInfo[0]?.name} placeholder='Enter View Name' />
                <input className="form-control mb-3 border" type='text' id="age" value={props.popupInfo[0]?.age} placeholder='Enter View Name' />
                <div className='form-radio-wrap d-flex'>
                  <div className="form-check mr-3">
                      <input className="form-check-input" type="radio" name="gender" id="male" checked={props.popupInfo[0]?.gender === 'male' ? true : false} />
                      <label className="form-check-label" for="male">
                          Male
                      </label>
                  </div>
                  <div className="form-check mb-3 ms-3">
                      <input className="form-check-input" type="radio" name="gender" id="female" checked={props.popupInfo[0]?.gender === 'female' ? true : false} />
                      <label className="form-check-label" for="female">
                          Female
                      </label>
                  </div>
                </div>
                <input className="form-control mb-3 border" type='text' placeholder='Other Information' />
                <input className="form-control mb-3 border" type='text' placeholder='Other Information' />
                <input className="form-control mb-3 border" type='text' placeholder='Other Information' />
                
                <Button className='btn-danger ms-auto me-3' variant="primary" onClick={props.handleClose}>Cancel</Button>
                <Button className='btn-danger ms-auto me-3' variant="primary" onClick={props.confirmHandler}>
                    Confirm
                </Button>
            </div>
          </Modal.Body>
         
      </Modal> 
    </>
  )
}

export default PatientInformationForm
