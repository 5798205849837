import React from "react";
import { Col } from "react-bootstrap";
import dragIcon from "../assets/images/drag-icon.png";
import MenIcon from "../assets/images/men-icon.svg";
import WomenIcon from "../assets/images/women-icon.svg";
import { Id } from "react-flags-select";
import DragIcon from "../assets/images/drag-icon.svg";
import UserIcon from "../assets/images/user-icon.png";
import MedicalCross from "../assets/images/medical-cross.png";

import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PatientView from "./Modals/PatientView";
import { useSelector } from "react-redux";

function calculateAge(birthdateStr) {
  if(!birthdateStr) return
  // Parse the birthdate string into a Date object
  const birthdate = new Date(birthdateStr);

  // Get today's date
  const today = new Date();

  // Calculate the age in years
  let age = today.getFullYear() - birthdate.getFullYear();

  // Adjust if the birthday hasn't occurred yet this year
  const hasBirthdayOccurred =
    today.getMonth() > birthdate.getMonth() ||
    (today.getMonth() === birthdate.getMonth() &&
      today.getDate() >= birthdate.getDate());

  if (!hasBirthdayOccurred) {
    age -= 1;
  }

  return age;
}

const DesignFloorRoomItem = ({
  onDragStart,
  room,
  RoomArray,
  removeRoom,
  onDragLeave,
  onDragEnter,
  onDragEnd,
  onDragOver,
  onDrop,
  id,
  isEditable,
  beds,
}) => {
  const [show, setShow] = useState(false);
  const [showpatientdetails, setShowpatientdetails] = useState(false);
  const patienthandleClose = () => setShowpatientdetails(false);
  const patienthandleShow = () => {
    setShowpatientdetails(true);
  };

  const { occupiedBeds, patientDetails } = useSelector(
    (state) => state.inPatient
  );

  const currentRoomId = RoomArray?.find((obj) => obj.FloorId === id)?.OBJID;
  const bedsss = beds?.filter((obj) => obj?.PARID === currentRoomId);
  const [currentPatient, setCurrentPatient] = useState({});

  const showPatientdetailHandler = (patiendId) => {
    setShowpatientdetails(true);
    setShow(false);
    const patient = patientDetails?.find((obj) => obj?.PatientId === patiendId);
    setCurrentPatient(patient);
  };
  return (
    <div
      style={{
        cursor: "grab",
      }}
      className="empty "
      key={id}
      id={`left-section.${id}`}
      //RoomList={FloorArray}
      onDragLeave={onDragLeave}
      onDragEnter={onDragEnter}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDoubleClick={(e) => {
        if (!isEditable) {
          setShow(true);
        }
      }}
      onDragStart={onDragStart}
    >
      {room === "fill"
        ? RoomArray.map((item) => {
            // console.log(item.FloorId, id, "-----id here");
            return id === item.FloorId ? (
              <div
                className=" w-100 h-100 position-relative"
                draggable={isEditable}
              >
                {isEditable ? (
                  <div
                    className="bed-close-icon position-absolute end-0 top-0 bg-dark rounded p-1 text-white"
                    role="button"
                    onClick={() => removeRoom(item.id, id)}
                  >
                    X
                  </div>
                ) : null}
                <div class="floor-room bg-light p-3 text-dark position-relative fill">
                  <img
                    src={dragIcon}
                    alt=""
                    className="position-absolute me-2 mb-2 end-0 bottom-0"
                    draggable={false}
                  />
                  <h5 class="fw-bold lh-1">{item.name}</h5>
                  <p>{item.description}</p>
                </div>
              </div>
            ) : null;
          })
        : null}

      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        onDoubleClick={(e) => e.stopPropagation()}
        className="floor-room-popup"
      >
        <Modal.Body className="d-flex flex-wrap">
          {/* <div className="bed-box ">
            <div className="bed position-relative align-items-center men-bed">
              <div className="lefticon ">
                <img src={UserIcon} alt="" className="user-icon" />
              </div>
              <div className="bed-patient-details d-flex flex-column text-dark">
                <h6 className="m-0">Patient 01</h6>
                <p className="d-flex flex-column lh-1 pt-2">
                  <span className="age">
                    32/<span className="sex">male</span>
                  </span>
                  <button
                    type="button"
                    onClick={showPatientdetailHandler}
                    className="patient-view-details pt-3"
                  >
                    {" "}
                    View Details
                  </button>
                </p>
              </div>
            </div>
          </div> */}
          {bedsss?.map((bed) => {
            const getPatientDetails = occupiedBeds?.find(
              (obj) => obj.Bed === bed.SHORT
            );
            const patient = patientDetails?.find(
              (obj) => obj?.PatientId === getPatientDetails?.PatientId
            );
            return occupiedBeds?.find((obj) => obj.Bed === bed.SHORT) ? (
              <div className="bed-box d-flex flex-column justify-content-center align-items-center gap-2 ">
                <div className="bed position-relative align-items-center men-bed">
                  <div className="lefticon ">
                    <img src={UserIcon} alt="" className="user-icon" />
                  </div>
                  <div className="bed-patient-details d-flex flex-column text-dark">
                    <h6 className="m-0">
                      {patient?.FirstName + " " + patient?.LastName}
                    </h6>
                    <p className="d-flex flex-column lh-1 pt-2">
                      <span className="age">
                        {calculateAge(patient?.BirthDate)}{" "}
                        <span className="sex">
                          {patient?.IsSexUnknown
                            ? "others"
                            : patient?.IsMale
                            ? "male"
                            : "female"}
                        </span>
                      </span>
                      <button
                        type="button"
                        onClick={() =>
                          showPatientdetailHandler(patient.PatientId)
                        }
                        className="patient-view-details pt-3"
                      >
                        {" "}
                        View Details
                      </button>
                    </p>
                  </div>
                </div>
                <h6>{bed.STEXT}</h6>
              </div>
            ) : (
              <div className="bed-box d-flex flex-column justify-content-center align-items-center gap-2 ">
                <div className="bed position-relative align-items-center null">
                  <div className="lefticon ">
                    <img src={UserIcon} alt="" className="user-icon" />
                  </div>
                  <div className="bed-patient-details d-flex flex-column text-dark">
                    <img
                      src={MedicalCross}
                      alt=""
                      className="medical-cross-icon w-100"
                    />
                  </div>
                </div>
                <h6>{bed.STEXT}</h6>
              </div>
            );
          })}
        </Modal.Body>
      </Modal>

      <PatientView
        show={showpatientdetails}
        details={currentPatient}
        handleClose={patienthandleClose}
        handleShow={patienthandleShow}
      />
    </div>
  );
};

export default DesignFloorRoomItem;