import React from 'react'
import { Col } from 'react-bootstrap';
import DragIcon from '../assets/images/drag-icon.svg';
import MenIcon from '../assets/images/men-icon.svg';
import WomenIcon from '../assets/images/women-icon.svg';

const PatientRoomItems = ({gender, name, age, bed, onDragStart, onDragEnd, id }) => {
  return (
        <Col md={3} key={id} id={id}>
            <div className='patient-wr-item bg-light rounded py-3 ps-3 position-relative mb-4'
            id={id}
                draggable
                onDragStart={e => onDragStart(e)}
                onDragEnd={e => onDragEnd(e)}
            >
               
                <img src={DragIcon} alt='' className='position-absolute me-2 mb-2 end-0 bottom-0' />                            
                <div className='patient-box d-flex'>
                    <img src={gender==="male" ? MenIcon : WomenIcon} alt='' />
                    <div className='text-dark ps-3'>
                        <h6 className='fw-bold'>{name}</h6>
                        <p className='d-flex flex-column'>
                            <span className='age'>{age}</span>
                            <span className='sex'>{gender}</span>
                        </p>
                    </div>
                </div>
                
            </div>
        </Col>
  )
}

export default PatientRoomItems
