import React from 'react';
import { Field } from 'formik';

const DecimalInput = ({ name }) => (    
  <div className='row'>
    <div className='col-md-6'>
      <Field type="number" id={name} name={name} placeholder={name} step=".01" className="form-control" />
    </div>
    <div className='col-md-6'>
      <Field type="number" id={`${name}_2`} name={`${name}_2`} placeholder={name} step=".01" className="form-control" />
    </div>
  </div>
);

export default DecimalInput;
